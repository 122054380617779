export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION
export const COGNITO_USERPOOL_ID = process.env.REACT_APP_COGNITO_USERPOOL_ID
export const COGNITO_USERPOOL_WEB_CLIENT_ID = process.env.REACT_APP_COGNITO_USERPOOL_WEB_CLIENT_ID
export const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN
export const COGNITO_SIGN_IN_URL = process.env.REACT_APP_COGNITO_SIGN_IN_URL
export const COGNITO_SIGN_OUT_URL = process.env.REACT_APP_COGNITO_SIGN_OUT_URL
export const CHANGE_PASSWORD_URL = process.env.REACT_APP_CHANGE_PASSWORD_URL
export const DOWNLOAD_ANTIGRAM_URL = process.env.REACT_APP_DOWNLOAD_ANTIGEN_URL
export const REACT_APP_API_ENDPOINT=process.env.REACT_APP_API_ENDPOINT
export const AUTH_PROVIDER=process.env.REACT_APP_AUTH_PROVIDER
export const LOGIN_ENDPOINT = process.env.REACT_APP_LOGIN_ENDPOINT