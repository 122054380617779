import React, { useEffect, useState, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import { ReactComponent as ArrowDown } from "../../../../assets/images/ArrowDown.svg";
import PositionedSnackbar from "../../../SnackbarPopup/SnackbarPopup";
import "./index.scss";
import { IconButton } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import Constants from "../../../../languages/language";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
const datTypes = Constants.antigramTable.workupHeader.DAT.labels;
const antTypConsts = Constants.antigramTable.workupHeader.antigenTyping;
const datDropDownValues = Constants.antigramTable.workupHeader.DAT.dropDown;
const dat = Constants?.AntigenHeader;

// DAT Alert message Code Commented for Future Use

// const warningMessage = () => {
//   return (
//     <React.Fragment>
//       <WarningIcon className="warning-icon" style={{ color: "#F2A900" }} />
//       <span className="alert-note">
//         <strong>{antTypConsts.changeAlertTitle}:</strong>{" "}
//         {antTypConsts.changeAlertMessage}
//       </span>
//     </React.Fragment>
//   );
// };

const DatResults = ({
  onDatDataChange,
  datResultData,
  workupStatus,
  disableSave,
  completeAlert,
}) => {
  const { datData, disabledDropdowns } = datResultData;
  const [showAlertMsg, setShowAlertMsg] = useState(false);
  const isWorkupCompleted = workupStatus === "COMPLETED";

  const handleDropdownDataChange = (name, value) => {
    let newDatData = { ...datData, [name]: value };
    onDatDataChange(newDatData);

    if (!sessionStorage.getItem("datAlertShown")) {
      sessionStorage.setItem("datAlertShown", true);
      setShowAlertMsg(true);
    }
  };

  const isDropdownDisabled = (name) => {
    return isWorkupCompleted || disabledDropdowns.includes(name);
  };

  return (
    <div id="datWrapper" className="dat-result-container">
      {/* DAT Alert message Code Commented for Future Use */}

      {/* {showAlertMsg && (
        <PositionedSnackbar
          fwdStyles={{
            color: "#34383C",
            backgroundColor: "#FFF2D0",
            borderColor: "#F2A900",
          }}
        >
          {warningMessage()}
        </PositionedSnackbar>
      )} */}
      <div className="container-wrapper">
        {/* Poly       */}
        <div className="container-labels">
          <span>{datTypes.poly}</span>
        </div>
        <DatDropdown
          name="poly"
          data={datData.poly}
          onChange={handleDropdownDataChange}
          isDisabled={isDropdownDisabled("poly")}
          isWorkupCompleted={isWorkupCompleted}
          completeAlert={completeAlert}
          disableSave={disableSave}
        ></DatDropdown>
      </div>
      {/* IgG */}
      <div className="container-wrapper">
        <div className="container-labels">
          <span>{datTypes.igg}</span>
        </div>
        <DatDropdown
          name="igg"
          data={datData.igg}
          onChange={handleDropdownDataChange}
          isDisabled={isDropdownDisabled("igg")}
          isWorkupCompleted={isWorkupCompleted}
          completeAlert={completeAlert}
          disableSave={disableSave}
        ></DatDropdown>
      </div>
      {/* C3 */}
      <div className="container-wrapper">
        <div className="container-labels">
          <span>{datTypes.c3}</span>
        </div>
        <DatDropdown
          name="c3"
          data={datData.c3}
          onChange={handleDropdownDataChange}
          isDisabled={isDropdownDisabled("c3")}
          isWorkupCompleted={isWorkupCompleted}
          completeAlert={completeAlert}
          disableSave={disableSave}
        ></DatDropdown>
      </div>

      {/* Control */}
      <div className="container-wrapper">
        <div className="container-labels">
          <span>{datTypes.control}</span>
        </div>
        <DatDropdown
          name="control"
          data={datData.control}
          onChange={handleDropdownDataChange}
          isDisabled={isDropdownDisabled("control")}
          isWorkupCompleted={isWorkupCompleted}
          completeAlert={completeAlert}
          disableSave={disableSave}
        ></DatDropdown>
      </div>
    </div>
  );
};

export default DatResults;
const dropdownValues = datDropDownValues;

const getMenuDisplayText = (value) => {
  return value === "NT" || value == null ? "Not Tested" : value;
};

const getDisplayText = (data) => {
  if ((data.value === "NT" || data.value == null) && data.mf) {
    return <span className="mixed-field">{dat.mf}</span>;
  }

  return (
    <>
      {getMenuDisplayText(data.value)}
      {data.mf && <span className="mixed-field">{dat.mf}</span>}
    </>
  );
};

const DatDropdown = ({
  name,
  data = {},
  onChange,
  isDisabled,
  isWorkupCompleted,
  completeAlert,
  disableSave,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const open = Boolean(anchorEl);
  const parentRef = useRef();

  useEffect(() => {
    setAnchorEl(parentRef.current);
  }, [parentRef.current]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleLabelClick = () => {
    if (isWorkupCompleted) {
      return;
    }

    if (isDisabled) {
      setShowMessage(true);
      return;
    }

    setIsOpen(true);
  };

  const handleMenuClick = (value) => {
    const mf = value === "NT" || value == null ? false : data.mf;

    if (data.value === value) {
      value = null;
    }

    onChange(name, { value, mf });
    setIsOpen(false);
    disableSave();
  };

  const handleApplyMixedFieldChange = (event) => {
    onChange(name, {
      value: data.value,
      mf: event.target.checked,
    });
    setIsOpen(false);
    disableSave();
  };

  const handleClickAway = () => {
    setShowMessage(false);
  };

  const CheckIsSelected = (value) => {
    if (value === "NT" && data.value == null) {
      return true;
    }
    return value === data.value;
  };

  return (
    <>
      <span
        className={`dat-typing-card${
          isDisabled && !isWorkupCompleted ? " disabled" : ""
        }`}
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        ref={parentRef}
        onClick={isWorkupCompleted ? completeAlert : null}
      >
        <>
          {getDisplayText(data)}
          {!isWorkupCompleted && (
            <IconButton
              onClick={handleLabelClick}
              className="arrow-down-button"
            >
              <ArrowDown className={isDisabled ? "arrow-disabled" : ""} />
            </IconButton>
          )}
        </>
      </span>

      {showMessage && (
        <ClickAwayListener key={Math.random()} onClickAway={handleClickAway}>
          <Popper
            className="antyTypCellPopper"
            open={true}
            anchorEl={parentRef.current}
          >
            <p>{antTypConsts.disabledTooltip}</p>
          </Popper>
        </ClickAwayListener>
      )}
      {isOpen && (
        <Menu
          className="dat-dropdown-menu"
          MenuListProps={{ "aria-labelledby": "fade-button" }}
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem>
            <span className="dat-dropdown-headers">{dat.strength}</span>
          </MenuItem>
          {dropdownValues.map((value) => (
            <MenuItem
              key={value}
              value={value}
              onClick={() => handleMenuClick(value)}
              className={CheckIsSelected(value) ? "selected-menu" : null}
            >
              {getMenuDisplayText(value)}
            </MenuItem>
          ))}
          <MenuItem>
            <span className="dat-dropdown-headers">{dat.mixedfields}</span>
          </MenuItem>
          <MenuItem className="switch-btn">
            <Switch
              inputProps={{ "aria-label": "controlled" }}
              size="small"
              checked={data.mf}
              onClose={handleClose}
              onChange={handleApplyMixedFieldChange}
            />
            <span>{dat.applyMixedField}</span>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};
