import React, { useState, useEffect, useRef, useContext } from "react";
import TestTable from "../../components/testTable/TestTable";
import { getResults, getUniqueResults } from "../../Redux/Actions/results";
import { getDatePartDayJs, getTableDateTimeFormat } from "../../utils/date";
import { getMatchingField as getMatchingFieldUtil } from "../../utils/search";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { saveFiltersToApi } from "../../Redux/Actions/test-table-filter";
import { nameFormatter } from "../../utils/tableValueFormatter";
import Constants from "../../languages/language";
import { SpinnerContext } from "../../context/spinner-context.js";
import {
  caseInsensitiveTextSortAsc,
  caseInsensitiveTextSortDesc,
} from "../../utils/column-sort.js";
import {
  addToWorkupStorage,
  createOrEditWorkupStorage,
  notificationStorage,
  resetAddToWorkupFlow,
  resetNotificationFlow,
} from "../../services/communicationService";
import { SuccessPopup } from "../../components/successMessage/successPopup";
import RemoveTestModal from "../../../src/views/singleWorkup/RemoveTestModal";
import { userInfo } from "../../Redux/Store/user-info";
import { getTestDataFromPanel } from "../../utils/workup";
import { getStatus } from "../../utils/global";
const statusSort = Constants.testTable.statusSorting;
const sidebar = Constants.SideBar_Navigation;

const matcher = {
  result_dt: (value, searchText) => {
    return getTableDateTimeFormat(value).includes(searchText);
  },
};

const ignoreFields = ["id", "workup_id", "test_result_reference_key","antigram_reference_key"];

const getMatchingField = (row, searchText) => {
  return getMatchingFieldUtil(row, searchText, matcher, ignoreFields);
};

const getDataSatisfyingSearch = (data, searchText) => {
  if (data.length === 0 || searchText == "") {
    return data;
  }

  const lowerCaseSearchText = searchText.toLowerCase();

  const filteredData = data.filter((r) => {
    const matchingField = getMatchingField(r, lowerCaseSearchText);
    return matchingField != null;
  });
  return filteredData;
};

const getFilteredData = (data, filters) => {
  let filteredData = data;

  if (filters.statuses && filters.statuses.length > 0) {
    filteredData = filteredData.filter((p) =>
      filters.statuses.includes(p.status)
    );
  }

  if (filters.panelTypes && filters.panelTypes.length > 0) {
    filteredData = filteredData.filter((p) =>
      filters.panelTypes.includes(p.panel_type)
    );
  }

  if (filters.laboratories && filters.laboratories.length > 0) {
    filteredData = filteredData.filter((p) =>
      filters.laboratories.includes(p.laboratory)
    );
  }

  if (filters.startDate) {
    const startDateDayJS = getDatePartDayJs(filters.startDate);

    filteredData = filteredData.filter((p) => {
      const dt = getDatePartDayJs(new Date(p.result_dt));
      return dt >= startDateDayJS;
    });
  }

  if (filters.endDate) {
    const endDateDayJS = getDatePartDayJs(filters.endDate);

    filteredData = filteredData.filter((p) => {
      const dt = getDatePartDayJs(new Date(p.result_dt));
      return dt <= endDateDayJS;
    });
  }

  return filteredData;
};

const sortAscFuncs = {
  status: (a, b) =>
    statusSort[a["status"].replace(" ", "")] <
    statusSort[b["status"].replace(" ", "")]
      ? -1
      : 1,
  regular: (col) => (a, b) => caseInsensitiveTextSortAsc(a[col], b[col]),
};

const sortDescFuncs = {
  status: (a, b) => sortAscFuncs.status(a, b) * -1,
  regular: (col) => (a, b) => caseInsensitiveTextSortDesc(a[col], b[col]),
};

const getFlattenedPosts = (posts) => {
  return posts?.map((p) => ({
    id: p.id, 
    sample_id: p?.sample?.unhashed,
    result_dt: p?.date_time,   
    lot_number: p.lot_number,
    // Commented for future use //
    // patient_id: p?.patient?.id,
    // patient_name: nameFormatter(p?.patient?.last_name, p?.patient?.first_name),
    laboratory: p?.workup?.laboratory,
    panel_type: p?.panel_type,
    workup_name: p?.workup?.name,
    workup_id: p?.workup?.id, 
    status: p.workup ? p.workup.status : "NEW",
    antigram_reference_key: p?.antigram_reference_key,
    test_result_reference_key: p?.test_result_reference_key,
  }));
};

const createWorkupFlowIgnoredFilters = ["status"];

export const PatientData = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location?.state;
  const initilizationStatusRef = useRef({ state: "initializing" });

  const { workupId } = useParams();

  let { isAddToWorkupFlow, isCreateOrEditWorkupFlow, isNotificationFlow } =
    locationState || {};
  let addTestResults = false;

  if (!props.addTestResults) {
    addTestResults = locationState?.isCreateOrEditWorkupFlow;
  }

  const postsRef = useRef({ allPosts: [], showLaboratory: true });
  const {
    current: { allPosts, showLaboratory },
  } = postsRef;

  const workupCreated =
    Constants.testTable.testTableHeader.workupCreatedSuccessMessage;
  const workupEdited =
    Constants.testTable.testTableHeader.workupEditedSuccessMessage;
  const editWorkupDeleteMessage =
    Constants.workupDetails.createData.editWorkupDelete;
  // Filtered and sorted posts
  const [posts, setPosts] = useState([]);
  const [apiError, setApiError] = useState();
  const [filters, setFilters] = useState({}); // Selected filters
  const [filterValues, setFilterValues] = useState({ laboratories: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [sortParam, setSortParam] = useState({});
  const [searchText, setSearchText] = useState(localStorage.getItem("searchText") ?? "");
  const [successPopupData, setSuccessPopupData] = useState({
    isOpen: isAddToWorkupFlow && addToWorkupStorage.isAddToWorkupFlowSuccess,
    message: addToWorkupStorage.isNewWorkup ? workupCreated : workupEdited,
  });

  const testResultInRemoval = useRef();

  // sampleid
  const [selectedTestResultIds, setSelectedTestResultIds] = useState(
    isCreateOrEditWorkupFlow
      ? (createOrEditWorkupStorage.selectedTestResults || []).map(
          (i) => i.test_result_reference_key
        )
      : []
  );

  // id
  const [highlightedTestResultIds, setHighlightedTestResultIds] = useState(
    isNotificationFlow &&
      notificationStorage.notificationData.related_object?.id != null
      ? [notificationStorage.notificationData.related_object.id]
      : []
  );

  useEffect(() => {
    setHighlightedTestResultIds(
      isNotificationFlow &&
        notificationStorage.notificationData.related_object?.id != null
        ? [notificationStorage.notificationData.related_object.id]
        : []
    );
  }, [
    isNotificationFlow,
    notificationStorage.notificationData.related_object?.id,
  ]);

  const { changeSpinnerStatus } = useContext(SpinnerContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateFromApiRef = useRef();

  const updateFromApi = async () => {
    let res;
    if (addTestResults) {
      res = await getUniqueResults();
    } else {
      res = await getResults();
    }
    if (res?.status !== 200) {
      var err = res;
      getStatus(err, navigate);
      changeSpinnerStatus(false);
    }
    if (res && res?.status == 200) {
      updateAllPosts(res?.data?.results);
    }
    setApiError(null);
  };

  const [isRemoveConfirmationOpen, setIsRemoveConfirmationOpen] =
    useState(false);

  const handleRemoveConfirmationClose = () => {
    setIsRemoveConfirmationOpen(false);
  };
  const handleTestDataRemoveConfirmation = () => {
    setIsRemoveConfirmationOpen(false);
    const testResultItem = testResultInRemoval.current;

    const newSelectedItems = selectedTestResultIds.filter(
      (test_result_reference_key) =>
        test_result_reference_key != testResultItem.test_result_reference_key
    );

    // find the matching panel in the original data
    const matchingPanel =
      createOrEditWorkupStorage.workupOriginalData.panels.find(
        (p) =>
          p.test_result_reference_key ==
          testResultItem.test_result_reference_key
      );

    if (matchingPanel != null) {
      createOrEditWorkupStorage.testDataToRemove.push(
        getTestDataFromPanel(matchingPanel)
      );
    }

    setSelectedTestResultIds(newSelectedItems);
  };

  const getPostsToShow = (searchText, filters) => {
    const flattenedPosts = getFlattenedPosts(postsRef.current.allPosts);
    let postsToShow = getDataSatisfyingSearch(flattenedPosts, searchText);
    postsToShow = getFilteredData(postsToShow, filters);
    return postsToShow;
  };

  updateFromApiRef.current = updateFromApi;

  useEffect(() => {
    if (initilizationStatusRef.current.state != "initialized") {
      return;
    }

    updateFromApi();
  }, [isCreateOrEditWorkupFlow]);

  useEffect(() => {
    changeSpinnerStatus(true);
    initialize();
    const timer = setInterval(() => updateFromApiRef.current(), 30000);
    initilizationStatusRef.current.state = "initialized";
    return () => clearInterval(timer);
  }, []);

  const initialize = async () => {
    await updateFromApi();

    if (!(isCreateOrEditWorkupFlow)) {
      var filters = userInfo.data.results_filter;

      if (filters) {
        setFilters(JSON.parse(filters) || {});
      }
    }
    changeSpinnerStatus(false);
  };

  const updateAllPosts = (posts) => {
    postsRef.current.allPosts = posts;
    const laboratories = [
      ...new Set(
        posts.filter((p) => p.workup != null).map((p) => p.workup.laboratory)
      ),
    ];
    postsRef.current.showLaboratory = laboratories.length > 1;
    setFilterValues({
      ...filterValues,
      laboratories: laboratories.map((l) => ({ name: l })),
    });
    updatePostsToShow();
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // Get current posts //
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // sorting for TestTable //
  const handleSortChanged = (field, sortDirection) => {
    setSortParam({ [field]: sortDirection });
  };

  //  On Page Change //
  const onChange = (event, nextPage) => {
    setCurrentPage(nextPage);
  };

  const updateFilters = async (filters) => {
    setFilters(filters);
    setCurrentPage(1);

    if (!(isAddToWorkupFlow || isCreateOrEditWorkupFlow)) {
      userInfo.data.results_filter = JSON.stringify(filters);

      const res = await saveFiltersToApi(filters);
      if (res?.status !== 200) {
        var err = res;
        getStatus(err, navigate);
        changeSpinnerStatus(false);
      }
    }
  };

  useEffect(() => {
    updatePostsToShow();
  }, [searchText, filters, sortParam, highlightedTestResultIds]);

  const updatePostsToShow = () => {
    const filteredPosts = getPostsToShow(searchText, filters);

    let sortedPosts = sortDataList(filteredPosts, sortParam);
    // keep selected test results on top
    if (isAddToWorkupFlow || isCreateOrEditWorkupFlow) {
      sortedPosts = [
        ...sortedPosts.filter(
          (r) =>
            selectedTestResultIds.find(
              (id) => id == r.test_result_reference_key
            ) != null
        ),
        ...sortedPosts.filter(
          (r) =>
            selectedTestResultIds.find(
              (id) => id == r.test_result_reference_key
            ) == null
        ),
      ];
    }

    if (isNotificationFlow) {
      sortedPosts = [
        ...sortedPosts.filter(
          (r) => highlightedTestResultIds.find((id) => id == r.id) != null
        ),
        ...sortedPosts.filter(
          (r) => highlightedTestResultIds.find((id) => id == r.id) == null
        ),
      ];
    }

    setPosts(sortedPosts);
  };

  const sortDataList = (data, sortParam) => {
    let sortFunc;

    const keys = Object.keys(sortParam);
    // no sort param available
    if (keys.length == 0) {
      sortFunc = sortDescFuncs["regular"]("result_dt");
    } else {
      const field = keys[0];

      // Ascending
      if (sortParam[field] == 0) {
        if (sortAscFuncs[field]) {
          sortFunc = sortAscFuncs[field];
        } else {
          sortFunc = sortAscFuncs["regular"](field);
        }
      }

      // Descending
      else if (sortParam[field] == 1) {
        if (sortDescFuncs[field]) {
          sortFunc = sortDescFuncs[field];
        } else {
          sortFunc = sortDescFuncs["regular"](field);
        }
      }
    }

    return data.sort(sortFunc);
  };

  const handleSearchText = (searchText) => {
    setSearchText(searchText);
    setCurrentPage(1);
  };

  const handleAddToWorkup = (testResult) => {
    resetAddToWorkupFlow();
    addToWorkupStorage.selectedTestResult = testResult;
    navigate(`/workup-home?addToWorkup=true`, {
      state: {
        isAddToWorkupFlow: true,
        sidebarSelection: sidebar.Workups,
      },
    });
  };

  const handleSuccessPopupClose = () => {
    setSuccessPopupData({ isOpen: false });
  };

  const handleAddTestResults = () => {
    createOrEditWorkupStorage.selectedTestResults = getFlattenedPosts(
      postsRef.current.allPosts
    ).filter(
      (r) =>
        selectedTestResultIds.find(
          (test_result_reference_key) =>
            test_result_reference_key == r.test_result_reference_key
        ) != null
    );

    navigate(workupId ? `/single-workup/${workupId}` : "/single-workup", {
      state: { isAddToWorkupFlow, isCreateOrEditWorkupFlow },
    });
  };

  const handleCancelAddTestResult = (workupId) => {
    navigate(workupId ? `/single-workup/${workupId}` : "/single-workup", {
      state: { isAddToWorkupFlow, isCreateOrEditWorkupFlow },
    });
  };

  const handleSelectedChange = (testResult, isSelected) => {
    if (isCreateOrEditWorkupFlow && !isSelected) {
      const workupOriginalData = createOrEditWorkupStorage.workupOriginalData;
      if (
        workupOriginalData != null &&
        workupOriginalData.panels.find(
          (p) => p.sample.unhashed == testResult.sample_id
        ) != null
      ) {
        testResultInRemoval.current = testResult;
        setIsRemoveConfirmationOpen(true);
        return;
      }
    }

    const newSelectedItems = selectedTestResultIds.filter(
      (test_result_reference_key) =>
        test_result_reference_key != testResult.test_result_reference_key
    );

    if (isSelected) {
      newSelectedItems.push(testResult.test_result_reference_key);
    }

    setSelectedTestResultIds(newSelectedItems);
  };

  const handleClickedOutsideTable = () => {
    if (isNotificationFlow) {
      resetNotificationFlow();
      navigate(`/`);
    }
  };

  const showSampleIdDetails = !(isAddToWorkupFlow || isCreateOrEditWorkupFlow);

  return (
    <div>
      <RemoveTestModal
        open={isRemoveConfirmationOpen}
        handleClose={handleRemoveConfirmationClose}
        handleProceed={handleTestDataRemoveConfirmation}
        text={
          createOrEditWorkupStorage.isEdit ? editWorkupDeleteMessage : undefined
        }
      />
      {successPopupData.isOpen && (
        <SuccessPopup onClose={handleSuccessPopupClose}>
          {successPopupData.message}
        </SuccessPopup>
      )}
      <TestTable
        addTestResults={addTestResults}
        posts={currentPosts}
        setPosts={setPosts}
        filters={filters}
        filterValues={filterValues}
        ignoredFilters={
          isCreateOrEditWorkupFlow ? createWorkupFlowIgnoredFilters : []
        }
        updateFilters={updateFilters}
        showLaboratory={showLaboratory}
        onSearchTextChange={handleSearchText}
        searchText={searchText}
        onSortChanged={handleSortChanged}
        sortParam={sortParam}
        serverError={apiError}
        onAddToWorkup={handleAddToWorkup}
        selected={selectedTestResultIds}
        highlighted={highlightedTestResultIds}
        onSelectedChange={handleSelectedChange}
        onAddTestResults={handleAddTestResults}
        onCancelAddTestResult={handleCancelAddTestResult}
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        onChange={onChange}
        lastIndex={indexOfLastPost}
        startIndex={indexOfFirstPost}
        currentPosts={currentPosts}
        showSampleIdDetails={showSampleIdDetails}
        onClickedOutsideTable={handleClickedOutsideTable}
      />
    </div>
  );
};
