import React from "react";
import { Drawer } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import { CrossMask, GreenTick } from "../../../utils/icons";
import {
  Button,
  Box
} from "@mui/material";
import { DeleteWarning } from "../../Notes/DeleteWarning";
import { TagsInput } from "react-tag-input-component";
import LoadingSpinner from "../../Notes/LoadingSpinner";
import Constants from "../../../languages/language"
const waringMsg = Constants?.WorkupNotes;
const nosmpls = Constants?.WorkupNotes?.noSampleId;
const DrawerData = ({
  open,
  onClose,
  title,
  list_note,
  sample_id,
  done_btn,
  addSampleIdStatus,
  samplIdHeading,
  saved,
  failedLength,
  sampleIdFailedMessage,
  sampleIDSucessMessage,
  handleAddMessageClose,
  Add_Sample,
  sample_list_note,
  placeholder_Sample,
  handleInputChange,
  textAreaMessage,
  handleSaveSampleIDs,
  samplisStatus,
  handleDeleteMessageClose,
  isTagDeleteClicked,
  deleteSampleIdStatus,
  handleDeleteWarningClose,
  handleDeleteWarningYes,
  enterSampleConst,
  onClick,
  unlinkedSampleIDs,
  handleRemoveTag,
  isLoading,
  boxClass,
  deleteSample,
  deleteSampleId,
  Sample_Note,
  Separate_Sample,
  textAreaBox,
}) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      {addSampleIdStatus === true ? (
        <div className="deletesampleId-success-message">
          <GreenTick width={30} height={25} />
          <span>
            <b>{samplIdHeading}: </b>
            {saved === 0 ? `${failedLength} ${sampleIdFailedMessage}` : ""}
            {failedLength === 0 ? `${saved} ${sampleIDSucessMessage}` : ""}
            {failedLength !== 0 && saved !== 0
              ? `${saved} ${sampleIDSucessMessage} ${failedLength} ${sampleIdFailedMessage}`
              : ""}
          </span>
          <div style={{ cursor: "pointer" }} onClick={handleAddMessageClose}>
            <CrossMask width={15} height={15} />
          </div>
        </div>
      ) : (
        ""
      )}
      {deleteSampleIdStatus === true ? (
        <div className="deletesampleId-success-message">
          <GreenTick width={30} height={25} />

          <div>
            <b>{samplisStatus?.deletesampleId}</b> {samplisStatus?.sampleID}{" "}
            {` "${deleteSampleId}"`} {samplisStatus?.sampleIdone}
          </div>
          <div style={{ cursor: "pointer" }} onClick={handleDeleteMessageClose}>
            <CrossMask width={15} height={15} />
          </div>
        </div>
      ) : (
        ""
      )}
      {isTagDeleteClicked === true ? (
        <DeleteWarning
          title={deleteSample.heading}
          bodyTitle={deleteSample.title}
          deleteMsg={waringMsg?.accept} 
          sampleid={`${deleteSampleId} ?`}
          handleDeleteWarningClose={(value) => {
            handleDeleteWarningClose(value);
          }}
          handleDeleteWarningYes={(sampleId) => {
            handleDeleteWarningYes(sampleId);
          }}
        />
      ) : (
        ""
      )}
      <Box className={boxClass}>
        <div className="sample-id-heading">
          {title}
          <span className="close-button" onClick={onClose}>
            <CloseIcon />
          </span>
        </div>
        <div className="sample-data">
          <div className="error-icon">
            <ErrorOutlineIcon className="error-ic" />
            <span className="sample-test-results-data">{list_note}</span>
          </div>
        </div>
        <div className="sample-input-box">
          <div className="input-heading">{sample_id}</div>
          {textAreaBox && (
            <>
              <textarea
                placeholder={placeholder_Sample}
                onInput={handleInputChange}
                value={textAreaMessage}
                className="input-text"
              ></textarea>
              <div className="input-note">{Separate_Sample}</div>
              <Button
                className={
                  textAreaMessage === ""
                    ? "sample-id-btn-disable"
                    : "sample-id-btn"
                }
                onClick={handleSaveSampleIDs}
                disabled={textAreaMessage.length === 0}
              >
                {Add_Sample}
              </Button>
            </>
          )}
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
          {
          unlinkedSampleIDs?.length >= 1 ? 
          (
          <TagsInput
            value={unlinkedSampleIDs}
            onRemoved={handleRemoveTag}
            placeHolder=""
            className="rti--container"
            addKeys={32}
          />
          ):(<div className="parent-nosampleid"><div className="no-sampleID">{!textAreaBox && nosmpls}</div></div>)
          }
          </>
        )}
        <div className="sample-id-footer">
          <Button className="sample-done-btn" onClick={onClose}>
            {done_btn}
          </Button>
        </div>
      </Box>
    </Drawer>
  );
};
export default DrawerData;
