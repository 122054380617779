import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Drawer,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Button
} from "@mui/material";
import Constants from "../../../languages/language";
import "./NameChangeDrawer.scss";
import AlertDialogModal from "../../alertDialogModal/AlertDialogModal";

const NameChangeDrawer = ({
  isOpen,
  handleNameChange,
  onClose,
  nameChangeData,
  disableSave,
  delFifthCol,
  nameChngPanelIndex
}) => {
  const textConstants = Constants.antigramTable.NameChangeDrawer;
  const fifthColConst = Constants?.fifthCol?.deletePopup;
  const [savePop, setSavePop] = useState(false);

  useEffect(() => {
    setNameInputVal(nameChangeData?.test_type);
    setEnzymeVal(nameChangeData?.enzyme || false);
  }, [nameChangeData, isOpen]);

  const [nameInputVal, setNameInputVal] = useState("");
  const handleInputChange = (e) => {
    e.target.value.length < 6 && setNameInputVal(e.target.value);
  };

  const [enzymeVal, setEnzymeVal] = useState(false);
  const handleEnzymeVal = (e) => {
    setEnzymeVal(e.target.checked);
  };

  const panelClose = () => {
    setNameInputVal("");
    setEnzymeVal("");
    onClose();
  };

  const handleDel = () => {
    handleNameChange({ columnName: nameInputVal, enzyme: enzymeVal });
    onClose();
    setSavePop(false)
    delFifthCol(nameChngPanelIndex)
  }

  const handleCancel = () => {
    setSavePop(false);
  };

  const handleSave = () => {
    if (nameInputVal?.length === 0) {
      setSavePop(true);
    } else {
      handleNameChange({ columnName: nameInputVal, enzyme: enzymeVal });
      onClose();
    }
    disableSave();
  };

  return (
    <div>
      {savePop ? (
        <AlertDialogModal
          open={savePop}
          dialogTitle={fifthColConst?.dialogTitle}
          dialogContent={fifthColConst?.dialogContent}
          handleClose={handleCancel}
          handleProceed={handleDel}
          btnNoText={fifthColConst?.cancel}
          btnYesText={fifthColConst?.delete}
        />
      ) : null}
      <Drawer
        className="name-change-drawer"
        anchor="right"
        open={isOpen}
        onClose={panelClose}
      >
        <Box className="panel-box">
          <div className="box-header">
            <span className="title">{textConstants?.headerTitle}</span>
            <span className="close-icon" onClick={panelClose}>
              <CloseIcon />
            </span>
          </div>
          <div className="box-body">
            <div className="input-wrapper">
              <label className="name-label">
                {" "}
                {textConstants?.nameInputLable}{" "}
              </label>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                className="name-input"
              >
                <TextField
                  placeholder={textConstants?.nameInputPlaceholder}
                  id="filled-basic"
                  InputProps={{ disableUnderline: true }}
                  label=""
                  variant="filled"
                  value={nameInputVal||""}
                  onChange={(e) => handleInputChange(e)}
                />
                <p className="note-msg">{textConstants?.errInputlimit}</p>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enzymeVal}
                    onChange={(e) => handleEnzymeVal(e)}
                  />
                }
                label={textConstants?.enzyme}
              />
            </div>
          </div>
          <div className="box-footer">
            <Button className="btn cancel-btn" onClick={panelClose}>
              {textConstants?.cancelBtn}
            </Button>
            <Button className="btn save-btn" onClick={handleSave}>
              {textConstants?.saveBtn}
            </Button>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};
export default NameChangeDrawer;
