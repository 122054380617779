import React from "react";
import "./index.scss";

const Footer = () => {
  const footerData = [
    {
      title: "Privacy",
      // content:
      //   "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eius mod tempor incidunt ut labore et dolore magna aliqua.",
    },
    {
      title: "Legal",
      // content:
      //   "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
    },
    {
      title: "Code Of Conduct",
      // content:
      //   "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
    },
    {
      title: "Support",
      // content:
      //   "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
    },
    {
      title: "Contact Us",
      // content:
      //   "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
    },
  ];
  
  return (
    <div className="footer-root">
      {footerData && footerData.map((ite,idx)=>{
        return(<div key={idx} className="privacy-notice">
        <span>{ite.title}</span>
        <span>
         {ite.content}
        </span>
      </div>)
      })}
    </div>
  );
};
export default Footer;
