import React, { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./index.scss";

const SearchFilter = ({ onSearchTextChange, searchText }) => {

  const [hasFocus, setHasFocus] = useState(false);

  const handleSearchChange = (e) => {
    const {
      target: { value },
    } = e;

    localStorage.setItem("searchText", value );

    onSearchTextChange(value);
  };

  const handleInputFocus = () => {
    setHasFocus(true);
  };

  const handleInputLostFocus = () => {
    setHasFocus(false);
  };

  return (
    <div>
      <TextField
        className="search"
        placeholder={hasFocus ? "" : "Search"}
        onFocus={handleInputFocus}
        onBlur={handleInputLostFocus}
        type="text"
        variant="outlined"
        fullWidth
        size="small"
        onChange={handleSearchChange}
        value={searchText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: hasFocus ? "#CF2F44" : null }}/>
            </InputAdornment>
          ),

          endAdornment: searchText && (
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                onSearchTextChange("")
                localStorage.removeItem("searchText");
              }}
            >
              <CloseIcon />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};

export default SearchFilter;