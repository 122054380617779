import React, { useState, useEffect } from "react";
import en from './en.json';
import fr from './fr.json';
import i18n from "../i18n"


const Language = () =>{
let defaultLang = i18n.language;
    let selectedLang;
    if(defaultLang === "en-US"){
        selectedLang = en;
    }else if(defaultLang === "fr-CA"){
        selectedLang = fr;
    }else{
        selectedLang = en;
    }
    return selectedLang;
}
export default Language();