export const getTestDataFromPanel = (panel) => {
  return {
    id: panel.id,
    antigram_reference_key: panel.antigram_reference_key,
    sample_id: panel.sample.unhashed,
    lot_number: panel.lot_number,
    result_dt: panel.result_dt,
    laboratory: panel.laboratory,
    panel_type: panel.panel_type,
    test_result_reference_key: panel.test_result_reference_key,
  };
};
