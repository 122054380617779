import React, { useEffect, useState, useContext } from "react";
import { Button } from "@mui/material";
import "./index.scss";
import {
  acceptTermsOfService,
  getTerms,
} from "../../Redux/Actions/terms-of-service";
import Constants from "../../languages/language";
import dayjs from "dayjs";
import { SpinnerContext } from "../../context/spinner-context.js";
import i18n from "../../i18n";
import {getStatus} from "../../utils/global"
import { useNavigate } from "react-router-dom";
const termsOfUse = Constants.acceptTermsOfService;
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export const TermsOfService = () => {
  const [termsData, setTermsData] = useState(null);
  const { changeSpinnerStatus } = useContext(SpinnerContext);
  const navigate=useNavigate();
  useEffect(() => {
    changeSpinnerStatus(true);
    getTerms().then((data) => {
      if(data?.status !== 200)
      {
        var err = data;
        getStatus(err, navigate);
        changeSpinnerStatus(false)
      }
      const browserLanguage =
        i18n.language === "fr-CA"
          ? { effective_date: data?.effective_date, lan: data["fr-CA"] }
          : { effective_date: data?.effective_date, lan: data["en-US"] };
      setTermsData(browserLanguage);
      changeSpinnerStatus(false);
    });
  }, []);

  const handleAcceptTerms = async() => {
    changeSpinnerStatus(true);
    const res= await acceptTermsOfService();
    if(res?.status !== 200)
    {
      var err = res;
      getStatus(err, navigate);
      changeSpinnerStatus(false);
    }
    changeSpinnerStatus(false);
    window.location.reload()
   
     
  };



  return (
    <div className="terms-of-service-container">
      <div className="terms-of-service-header">
        <div className="terms-of-use-title">{termsOfUse.title}</div>
        <p className="welcome-note">{termsOfUse.welcomeNote}</p>
      </div>
      <div className="terms-of-service-accept-agreement">
        <span className="effective-date">
          {termsOfUse.effectiveDate} :{" "}
          {dayjs(termsData?.effective_date).format("MMMM DD, YYYY")}
        </span>
        <div className="term-of-service-information">
          <span className="topic">{termsOfUse.topic.topic1}</span>
          <p className="paragraph">{termsData?.lan[0]}</p>
          <span className="topic">{termsOfUse.topic.topic2}</span>
          <p className="paragraph">{termsData?.lan[1]}</p>
        </div>
      </div>
      <div className="accept-button-wrapper">
        <Button onClick={handleAcceptTerms}>
          {termsOfUse.topic.acceptButton}
        </Button>
      </div>
    </div>
  );
};
