import React, { useState, useEffect, useContext } from "react";
import { aboutPageOverview } from "../../../Redux/Actions/aboutPageOverview";
import Constants from "../../../languages/language";
import { SpinnerContext } from "../../../context/spinner-context";
import "./about-page.scss";
import i18n from "../../../i18n";
import { getStatus } from "../../../utils/global";
import { useNavigate } from "react-router-dom";
import buildVersion from "../../../../src/appVersion.txt"

const AboutPageOverview = () => {
  const aboutPageConsts = Constants.aboutPageOverview;
  const { changeSpinnerStatus } = useContext(SpinnerContext);
  const [aboutPageData, setaboutPageData] = useState({});
  const [version, setVersion] = useState();
  const [buildName , setBuildName] = useState()
  const navigate = useNavigate();
  const arrData = [];
  const aboutPageOverviewFetch = async () => {
    changeSpinnerStatus(true);
    const res = await aboutPageOverview();
    const response = res.version;
    setVersion(response);
    if (res?.status !== 200) {
      var err = res;
      getStatus(err, navigate);
      changeSpinnerStatus(false);
    }
    const browserLanguage =
      i18n.language === "fr-CA" ? res?.about["fr-CA"] : res?.about["en-US"];
    Object.entries(browserLanguage).forEach(function (key, index) {
      arrData.push(key[1]);
      setaboutPageData(arrData);
    });
    changeSpinnerStatus(false);
  };
  useEffect(() => {
    aboutPageOverviewFetch();
  }, []);
  fetch(buildVersion).then((r) => r.text()).then(text  => {setBuildName(text.replace(/[^0-9A-Za-z-.]/g, ""))});
  return (
    <div className="about-page-container">
      <div className="about-page-wrapper">
        <span className="about-page-heading">
          {aboutPageConsts.aboutPageTitle}
        </span>
      </div>
      <div className="git-version-container">
        <p className="git-version">{aboutPageConsts.version}</p>
        <p className="git-version">{aboutPageConsts.ui} : {buildName} </p>
        <p className="git-version">{aboutPageConsts.api} : {version} </p>
      </div>
      {aboutPageData &&
        aboutPageData.length > 0 &&
        aboutPageData?.map((ite, idx) => {
          return (
            <div className="about-page-rule" key={idx}>
              <span className="rule-label">{`${ite?.title} `}</span>
              <p className="rule-paragraph">{ite?.body[0]}</p>
              <p className="rule-paragraph">{ite?.body[1]}</p>
              <p className="rule-paragraph">{ite?.body[2]}</p>
            </div>
          );
        })}
      
    </div>
  );
};

export default AboutPageOverview;
