import React from "react";
import { useLocation } from "react-router-dom";
import { ErrorCode } from "../../utils/icons";
import Constants from "../../languages/en.json";
import { AppBar, Toolbar } from "@mui/material";
import { userData } from "../../views/homePageLayout/apiData";
import "./index.scss";
import {LOGIN_ENDPOINT} from "../../utils/constants.js"

const ServerErr = () => {
  const location = useLocation();
  const err_handle = Constants.errorhandler;
  const status = location?.state?.status;
  const statusText = location?.state?.statusText || " ";

  return (
    <div className="server-container">
      <AppBar position="static">
        <Toolbar>
          {userData?.app_name}
          <div className="server-error-handler">
            <ErrorCode />
            <div className="status-msg">{status !== "511" ? err_handle.wentWrong:err_handle.logOut}</div>

            <div className="error-descr">
          {status === "511" ? (<span className="login-span">{err_handle.please}<a className="login-error" href={LOGIN_ENDPOINT}>{err_handle.login}</a>{err_handle.loginplease}</span>):
          ( <span>{statusText}</span> ) }
              <span>
                {status !== "511" ? err_handle.errCode:""} {status !== "511" ? status:""}
              </span>
            </div>
             { status === "511" ? (<p>
              {err_handle.needsupportdesk}
              <span>{` ${err_handle.supportDesk}`}</span>
            </p>):(<p>
              {err_handle.needSupport}
              <span>{` ${err_handle.supportDesk}`}</span>
            </p>)}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default ServerErr;
