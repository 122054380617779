import React, { useState, useRef, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import CustomizationTabs from "./CustomizationTabs.js";
import SubmitModal from "../submitModal/submitModal";
import dayjs from "dayjs";
import HeaderReviewModal from "../headerReviewModal/HeaderReviewModal.js";
import { styled } from "@mui/material/styles";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import WarningIcon from "@mui/icons-material/Warning";
import Alert from "@mui/material/Alert";
import InfoIcon from "@mui/icons-material/Info";
import { getPanelNotes } from "../../../Redux/Actions/panelnotes.js";
import {
  saveDraftAnalysis,
  saveSubmitWorkup,
  submitWorkup,
} from "../../../Redux/Actions/workups.js";
import { getUserInfo } from "../../../Redux/Actions/user.js";
import { SpinnerContext } from "../../../context/spinner-context";
import { ReactComponent as Vector } from "../../../assets/images/Vector.svg";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PositionedSnackbar from "../../SnackbarPopup/SnackbarPopup";
import { CrossMask, GreenTick } from "../../../utils/icons.js";
import { geSingletWorkups, autoControl } from "../../../Redux/Actions/workups";
import Constants from "../../../languages/language";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DateFormat } from "../../../utils/dateFormat.js";
import { getNotes } from "../../../Redux/Actions/notes.js";
import { FlyoutBox } from "../../Notes/Flyout.js";
import DownloadWorkup from "../downloadWorkup/DownloadWorkup.js";
import {
  createOrEditWorkupStorage,
  resetCreateOrEditWorkupFlow,
} from "../../../services/communicationService.js";
import { getStatus } from "../../../utils/global.js";
const datTypes = Constants.antigramTable.workupHeader.DAT.types;
const headersavesubmit = Constants.AntigenHeader;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "#000000",
    lineHeigth: 17,
    fontWeight: 400,
    padding: 10,
    borderRadius: 6,
    fontSize: 14,
    filter: "drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.08))",
  },
}));
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const workupSuccessMessage = Constants?.testTable?.testTableHeader;
export default function AntigramHeader({
  apiAllData,
  prevApiAllData,
  updateApiData,
  rmToggle,
  ruleType,
  updateRules,
  handleRMToggle,
  classificRule,
  downloadPanelExpand,
  completedAlert,
  completeAlert,
  completeAlertFalse,
  enableSave,
  disableSave,
  defaultSave,
  autoControlval,
  panelOrder
}) {
  const navigate = useNavigate();
  const location = useLocation();
  
  const datDataRef = useRef();
  const { panelId } = useParams();
  const workupSuccessMessage = Constants?.testTable?.testTableHeader;
  const antigramHederData = apiAllData?.workup;
  const { name, status, modified, patient, antibody_history, diagnosis } =
    antigramHederData;
  const [open, setOpen] = useState(false);
  const [reviewOpen, setReviewOpen] = useState(false);
  const { changeSpinnerStatus } = useContext(SpinnerContext);
  const [userData, setUserData] = useState({});
  const [notesCount, SetNotesCount] = useState();
  const [btnDisabled, SetBtnDisabled] = useState("disabled");
  const warnings = Constants?.workupDetails?.createData;
  const { isCreateOrEditWorkupFlow } = location.state || {};
  const [showWorkupCreatedAlert, setShowWorkupCreatedAlert] = useState(
    isCreateOrEditWorkupFlow && createOrEditWorkupStorage.isTheFlowSuccessful
  );

  const [activeTab, setActiveTab] = React.useState(0);
  const [downloadNotes, setDownloadNotes] = useState();

  const setActiveTabValue = (value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    getNotesCount();
  }, []);

  const getNotesCount = async () => {
    const res = await getNotes(antigramHederData.id);
    if(res?.status !== 200)
    {
      var err = res;
      getStatus(err, navigate);
      changeSpinnerStatus(false);
    }
    setDownloadNotes(res);
    SetNotesCount(res ? res?.length : 0);
  };
  const handleClickOpen = () => {
    setOpen(true);
    setReviewOpen(false);
  };
  // localStorage.removeItem("selectedAnalysisKey");
  // localStorage.removeItem("selectedAnalysis");
  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem("selectedAnalysisKey");
    localStorage.removeItem("selectedAnalysis");
    SetBtnDisabled("disabled");
  };
  const handleReviewClickOpen = () => {
    setReviewOpen(true);
  };
  const handleReviewClose = () => {
    setReviewOpen(false);
    localStorage.removeItem("selectedAnalysisKey");
    localStorage.removeItem("selectedAnalysis");
    SetBtnDisabled("disabled");
  };
  const [submit, setSubmit] = useState(false);
  const [saveMsg, setSaveMsg] = useState(false);
  const [conflictType, setConflictType] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const saveSubmitMeassage = () => {
    const { date_time, first_name, last_name } = userData;
    return (
      <React.Fragment>
        <CancelIcon className="warning-icon" style={{ color: "#8F191C" }} />
        <span className="alert-note">
          <strong>{warnings?.error}:</strong>{" "}
          {conflictType === "sameUser" && warnings?.conflictSameuser}
          {conflictType === "differentUser" && warnings?.conflict}
          {(conflictType === "sub-submitted" || conflictType === "save-submitted") && warnings?.conflictSubmit1}
          {<i>{first_name + " " + last_name}</i>}
          {warnings?.conflictSubmit2 + (date_time === undefined ? 'undefined' : dayjs(date_time).format(DateFormat() + " - HH:mm"))}
          {conflictType !== "sub-submitted" ? warnings?.refresh : warnings?.conflictSubmit3}
        </span>
      </React.Fragment>
    );
  };
  const saveSuccessfulMeassage = () => {
    return (
      <div>
        <CheckCircleIcon
          className="warning-icon"
          style={{ color: "#789D4A" }}
        />
        <span className="alert-note">
          <strong>{"Save successful"}</strong>
        </span>
      </div>
    );
  };
  const SaveSuccessAlert = () => {
    return (
      <PositionedSnackbar
        fwdStyles={{
          color: "#34383C",
          backgroundColor: "#EEFFF1",
          borderColor: "#789D4A",
          justifyContent: "space-between",
          // onClose={}
        }}
      >
        {saveSuccessfulMeassage()}
      </PositionedSnackbar>
    );
  };

  const SaveFirstAlert = () => {
    return (
      <PositionedSnackbar
        fwdStyles={{
          color: "#34383C",
          backgroundColor: "#FDEBF0",
          borderColor: "#8F191C",
          // onClose={}
        }}
      >
        {saveSubmitMeassage()}
      </PositionedSnackbar>
    );
  };

  const submitSuccessfulMeassage = () => {
    return (
      <div>
        <CheckCircleIcon
          className="warning-icon"
          style={{ color: "#789D4A" }}
        />
        <span className="alert-note">
          <strong>{ workupSuccessMessage?.saveworkup }</strong>{" "}
          {apiAllData?.workup?.name +
            Constants?.antigramTable?.saveSubmitData?.submittedMsg}
        </span>
      </div>
    );
  };

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const SubmitSuccessAlert = () => {
    return (
      <PositionedSnackbar
        fwdStyles={{
          color: "#34383C",
          backgroundColor: "#EEFFF1",
          borderColor: "#789D4A",
          justifyContent: "space-between",
          // onClose={}
        }}
      >
        {submitSuccessfulMeassage()}
      </PositionedSnackbar>
    );
  };

  const GetAutoControlApiObject = () => {
    let autoctrlObj=[];
    let colNo;
    for(let i=0; i< apiAllData?.panels?.length; i++){
      let autoConrtolId = null
    for(let j = 0; j < apiAllData?.panels[i]?.results?.length; j++){
      if(apiAllData.panels[i]?.results[j].cell_number === "AC"){
        autoConrtolId = j;
        break;
      }
    }
      apiAllData?.panels[i]?.panel?.panel_type === "Screen" ? colNo = autoConrtolId : colNo = autoConrtolId
      autoctrlObj.push({
        id: apiAllData?.panels[i]?.panel?.id,
        test_results: [
          {
            "cell_number": "AC",
            "columns": {
              "iat": apiAllData?.panels[i]?.results[colNo]?.columns["iat"]?.grade,
              "fic": apiAllData?.panels[i]?.results[colNo]?.columns["fic"]?.grade,
              "is": apiAllData?.panels[i]?.results[colNo]?.columns["is"]?.grade,
              "37c": apiAllData?.panels[i]?.results[colNo]?.columns["37c"]?.grade,
              "fifth_column": apiAllData?.panels[i]?.results[colNo]?.columns["fifth_column"]?.grade
            }
          }
        ]
      })
    }
    return autoctrlObj;
  };

  const GetDatApiObject = () => {
    const datData = datDataRef.current || {};
    const getFieldApiObject = (fieldName) => {
      var fieldData = datData[fieldName];
      if (fieldData) {
        return {
          grade: fieldData.value,
          mf: fieldData.mf === true,
        };
      } else {
        return {
          grade: null,
          mf: false,
        };
      }
    };
    const datApiObj = {};
    datTypes.forEach((key) => {
      datApiObj[key] = getFieldApiObject(key);
    });
    return datApiObj;
  };

  const downloadManual = (value) => {
    handleRMToggle(value);
  };
  
  let downloadApi = apiAllData;

  const warningMessage = () => {
    return (
      <React.Fragment>
        <WarningIcon className="warning-icon" style={{ color: "#F2A900" }} />
        <span className="alert-note">
          {Constants?.downloadWorkup?.alert_msg}
        </span>
      </React.Fragment>
    );
  };

  useEffect(() => {
    statusComplete;
  }, [completeAlert]);

  const statusComplete = () => {
    return (
      <PositionedSnackbar
        fwdStyles={{
          color: "#34383C",
          backgroundColor: "#E9F0F3",
          borderColor: "#364390",
          justifyContent: "space-between",
          // onClose={}
        }}
        onClose={completeAlertFalse}
      ><InfoIcon className="warning-icon" style={{ color: "#364390" }} />
        <span style={{ fontSize: "1rem" }}>
          <strong>{Constants?.submittedWorkup?.viewOnly}</strong>
          <span>{Constants?.submittedWorkup?.note}</span>
        </span>
      </PositionedSnackbar>
    );
  };

  const dwnldSuccess = () => {
    return (
      <div>
        <CheckCircleIcon
          className="warning-icon"
          style={{ color: "#789D4A" }}
        />
        <span className="alert-note">
          <strong>{Constants?.downloadWorkup?.success}</strong>
        </span>
      </div>
    );
  };
  const dwnldFail = () => {
    return (
      <div>
        <CancelIcon className="warning-icon" style={{ color: "#8F191C" }} />
        <span className="alert-note">
          <strong>{Constants?.downloadWorkup?.dwnld_fail}</strong>
        </span>
      </div>
    );
  };

  const downloadAlert = () => {
    return (
      <PositionedSnackbar
        fwdStyles={{
          color: "#34383C",
          backgroundColor: "#FFF2D0",
          borderColor: "#F2A900",
        }}
      >
        {warningMessage()}
      </PositionedSnackbar>
    );
  };

  const downloadSuccessAlert = () => {
    return (
      <PositionedSnackbar
      onClose = {downloadAlertClose}
        fwdStyles={{
          color: "#34383C",
          backgroundColor: "#EEFFF1",
          borderColor: "#789D4A",
          justifyContent: "space-between",
        }}
      >
        {dwnldSuccess()}
      </PositionedSnackbar>
    );
  };

  const [downloadAlertBox, setDownloadAlertBox] = useState(false);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [downloadFail, setDownloadFail] = useState(false);

  const downloadComplete = () => {
    setDownloadAlertBox(false);
    setDownloadSuccess(true);
  };

  const downloadFailAlert = () => {
    return (
      <PositionedSnackbar
        fwdStyles={{
          color: "#34383C",
          backgroundColor: "#FDEBF0",
          borderColor: "#789D4A",
          justifyContent: "space-between",
        }}
        onClose={downloadFailReset}
      >
        {dwnldFail()}
      </PositionedSnackbar>
    );
  };

  const downloadAlertClose = () =>{
    setDownloadSuccess(false)
  }

  const downloadFailFunc = () => {
    setDownloadAlertBox(false);
    setDownloadFail(true);
  };

  const downloadFailReset = () =>{
    setDownloadFail(false)
  }
  
  let panelIds = []
  let PanelLength = downloadApi?.panels?.length
  for (let i = 0; i < PanelLength; i++) {
    panelIds.push(downloadApi?.panels[i].panel.id)
  }

  let panelNotesData = []
  const panelNotes = async () => {
    for (let i = 0; i < panelIds.length; i++) {
        const res = await getPanelNotes(antigramHederData?.id,panelIds[i]);
        if(res?.status !== 200)
        {
          var err = res;
          getStatus(err,navigate);
          changeSpinnerStatus(false)
        }
        panelNotesData.push(res)
    }
  };

  const workupDownload = () => {
    downloadPanelExpand();
    setDownloadAlertBox(true);
    panelNotes();
    DownloadWorkup(
      downloadApi,
      downloadNotes,
      setActiveTabValue,
      downloadManual,
      downloadComplete,
      rmToggle,
      downloadFailFunc,
      panelNotesData,
      panelOrder
    );
  };

  /* onSubmit button click check conflicts */
  const checkSubmitConflict = async () =>{
    changeSpinnerStatus(true);
    setConflictType(false);
    setSaveMsg(false);
    const res = await geSingletWorkups(apiAllData?.workup?.id);
    if (res) {
      if(apiAllData?.e_tag === res?.e_tag){
        handleReviewClickOpen();
        changeSpinnerStatus(false);
      }else{
        setUserData({first_name: res?.workup?.modified?.first_name, last_name: res?.workup?.modified?.last_name, date_time: res?.workup?.modified?.date_time});
        const getUsrEmail = await getUserInfo();
        if (getUsrEmail && res?.workup?.status !== "COMPLETED") {
          getUsrEmail?.email === res?.workup?.modified?.email ? setConflictType("sameUser") : setConflictType("differentUser");
        } else if(getUsrEmail && res?.workup?.status === "COMPLETED") {
          setConflictType("sub-submitted");
        }

        setSaveMsg(true);
        changeSpinnerStatus(false);
      }
    } else {
      const err = res;
      getStatus(err, navigate);
      changeSpinnerStatus(false);
    }
  }

  /* Handle Submit workup */
  const sumbitWorkup = async (etag) => {
    const submitClssificType = localStorage.getItem("selectedAnalysisKey");
    const recFlag = submitClssificType === "Recommended" ? true : false;
    let apiObj;
    apiObj = {
      e_tag: etag,
      name: name,
      status: "completed",
      recommended: recFlag,
    };

    handleClose();
    const submitRes = await submitWorkup(panelId, apiObj);
    setOpen(false);
    setSaveSuccess(false);
    
    if (submitRes?.status === 200) {
      setSubmitSuccess(true);
    } else if (
      (submitRes?.status === 409 || submitRes?.status === 403) &&
      submitRes?.config?.method === "post"
    ) {
      const getUsrEmail = await getUserInfo();
      if (getUsrEmail && submitRes?.status !== 403) {
        getUsrEmail.email === submitRes?.data?.email
          ? setConflictType("sameUser")
          : setConflictType("differentUser");
      } else {
        setConflictType("sub-submitted");
      }
      setSaveMsg(true);
      setUserData(submitRes?.data);
    }  else {
      var err = submitRes;
      getStatus(err, navigate);
      setSubmitSuccess(false);
    }
    updateApiData();
    changeSpinnerStatus(false);
  };

  /* Submitting test data after analysis data */
  const submitToTestAPI = async (etag, actionType) => {
    const apiObj = {
      e_tag: etag,
      dat: GetDatApiObject(),
      phenotype_results: antTypeData,
      panels: GetAutoControlApiObject(),
    };

    const { id: workupId } = apiAllData.workup;
    const res = await autoControl(workupId, apiObj);
    if (res?.status === 200) {
      actionType === "submit"
        ? sumbitWorkup(res.data.e_tag)
        : (updateApiData(),
          setSaveSuccess(true),
          changeSpinnerStatus(false));
    } else if (
      (res?.status === 409 || res?.status === 403) &&
      res?.config?.method === "post"
    ) {
      handleClose();
      const getUsrEmail = await getUserInfo();
      if (getUsrEmail && res?.status !== 403) {
        getUsrEmail.email === res?.data?.email
          ? setConflictType("sameUser")
          : setConflictType("differentUser");
      } else {
        actionType === "submit" ? setConflictType("sub-submitted") : setConflictType("save-submitted");
      }

      setSaveMsg(true);
      setUserData(res?.data);
      changeSpinnerStatus(false);
    } else {
      //Handle error
      const err = res;
      getStatus(err, navigate);
      changeSpinnerStatus(false);
    }
  };

  /* Handle Save workup */
  const handleSaveDraftAnalysis = async (actionType) => {
    changeSpinnerStatus(true);
    setSaveSuccess(false);
    setSaveMsg(false);
    if (apiAllData?.panels !== null && apiAllData?.panels !== undefined) {
      let getAallPanelsData = [];
      let allPanelsData = {};
      for (let i = 0; i < apiAllData?.panels?.length; i++) {
        allPanelsData[i] = {
          id: apiAllData?.panels[i]?.panel?.id,
          user_mask: apiAllData?.panels[i]?.user_mask,
        };
        getAallPanelsData.push(allPanelsData[i]);
      }
      const apiObj = {
        e_tag: apiAllData.e_tag,
        panels: getAallPanelsData,
        interpretations: classificRule,
      };

      const res = await saveSubmitWorkup(panelId, apiObj);
      if (res?.status === 200) {
        submitToTestAPI(res?.data?.e_tag, actionType);
      }

      if (
        (res?.status === 409 || res?.status === 403) &&
        res?.config?.method === "post"
      ) {
        handleClose();
        const getUsrEmail = await getUserInfo();
        if (getUsrEmail && res?.status !== 403) {
          getUsrEmail.email === res?.data?.email
            ? setConflictType("sameUser")
            : setConflictType("differentUser");
        } else {
          actionType === "submit" ? setConflictType("sub-submitted") : setConflictType("save-submitted");
        }
        setSaveMsg(true);
        setUserData(res?.data);
        changeSpinnerStatus(false);
      }
      if (res?.status !== 200 && res?.status !== 409 && res?.status !== 403) {
        var err = res;
        getStatus(err, navigate);
        changeSpinnerStatus(false);
        setSaveMsg(false);
      }
    }

    if (apiAllData?.panels === null || apiAllData?.panels === undefined) {
      submitToTestAPI(apiAllData.e_tag, actionType);
    }
    defaultSave();
  };

  /* Call save function with submit flag */
  const handleSubmitWorkup = async () => {
    handleSaveDraftAnalysis("submit");
  };

  const selectedAnalysis = localStorage.getItem("selectedAnalysis");
  const selectedAnalysisData = selectedAnalysis;

  const handleEditWorkupPanel = () => {
    resetCreateOrEditWorkupFlow();
    createOrEditWorkupStorage.originalWorkupName = apiAllData.workup.name;
    createOrEditWorkupStorage.workupName = apiAllData.workup.name;
    createOrEditWorkupStorage.workupId = panelId;
    createOrEditWorkupStorage.isEdit = true;
    createOrEditWorkupStorage.origin = "antigram";
    navigate(`/single-workup/${panelId}`, {
      state: { isCreateOrEditWorkupFlow: true },
    });
  };

  const handleSuccessClose = () => {
    setShowWorkupCreatedAlert(false);
  };

  const handleDatDataChange = (data) => {
    datDataRef.current = data;
  };

  /* Restructure Antigen Typing obj */
  const [antTypeData, setAntTypeData] = React.useState({});
  let antTypeValOrgObj = apiAllData?.workup?.antigen_typing;

  useEffect(() => {
    let restructuredAntigTypeObj = {};
    if (antTypeValOrgObj) {
      let keys = Object.keys(antTypeValOrgObj);

      keys.forEach(function (key) {
        let grade = antTypeValOrgObj[key]?.grade?.value;
        let mf = antTypeValOrgObj[key]?.mf?.value;
        restructuredAntigTypeObj = {
          ...restructuredAntigTypeObj,
          [key]: { grade: grade, mf: mf },
        };
      });

      setAntTypeData(restructuredAntigTypeObj);
    }
  }, [antTypeValOrgObj]);

  const handleAntigenTyping = (antTypeObj) => {
    setAntTypeData(antTypeObj);
  };
  const handleWorkupNotesClose = () => {
    getNotesCount();
  };

  return (
    <div className="antigram-panel-header-wrapper">
      {saveMsg && SaveFirstAlert()}
      {saveSuccess && SaveSuccessAlert()}
      {submitSuccess && SubmitSuccessAlert()}
      {downloadAlertBox && downloadAlert()}
      {downloadSuccess && downloadSuccessAlert()}
      {downloadFail && downloadFailAlert()}
      {completedAlert && statusComplete()}
      {showWorkupCreatedAlert && (
        <div className="workup-created-message">
          <GreenTick width={25} height={25} />
          <span className="main-content">
          <span className="bold-content">
            {createOrEditWorkupStorage.isEdit
              ? workupSuccessMessage?.workupEdited
              : workupSuccessMessage?.workupCreated}
          </span>
          <span className="normal-content">
          {createOrEditWorkupStorage.isEdit
            ? workupSuccessMessage?.workupEditedMessage 
            : apiAllData?.panels !== null ? workupSuccessMessage?.workupCreatedSuccessMessage
            : workupSuccessMessage?.noResultsWorkupCreated}
            </span>
            </span>
          <div style={{ cursor: "pointer" }} onClick={handleSuccessClose}>
            <CrossMask width={15} height={15} />
          </div>
        </div>
      )}
      <SubmitModal
        ruledOutTab={true}
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        handleReviewClickOpen={handleReviewClickOpen}
        handleSubmitWorkupData={handleSubmitWorkup}
      />
      <HeaderReviewModal
        ruledOutTab={true}
        handleReviewClickOpen={handleReviewClickOpen}
        ruleType={ruleType}
        handleReviewClose={handleReviewClose}
        reviewOpen={reviewOpen}
        handleClickOpen={handleClickOpen}
        classificRule={classificRule}
        btnDisabled={btnDisabled}
      />
      <div className="antigram-panel-header">
        <div className="antigram-title-row">
          {name.length >= 15 ? (
            <span>
              <LightTooltip
                className="view-only-hover-popUp"
                title={name}
                arrow
                placement="top-start"
              >
                <h1 className="workuptitle">
                  {name && name.substring(0, 13) + "..."}
                </h1>
              </LightTooltip>
            </span>
          ) : (
            <span>
              <h1 className="workuptitle">{name}</h1>
            </span>
          )}
          {status === "COMPLETED" ? (
            ""
          ) : (
            <span
              className="edit-vector-icon"
              onClick={status === "COMPLETED" ? null : handleEditWorkupPanel}
            >
              <Vector />
            </span>
          )}
          <span
            className={
              status === "COMPLETED"
                ? `completed-status ${status}`
                : `status ${status}`
            }
          >
            {status}
          </span>
          <span className="last-modified">
            {status === "COMPLETED"
              ? apiAllData?.workup?.recommended === false
                ? Constants.antigramTable.saveSubmitData.manualSubmitted
                : Constants.antigramTable.saveSubmitData.recommondedSubmitted
              : Constants.antigramTable.saveSubmitData.lastSaved}{" "}
            {modified?.date_time === undefined
              ? "-"
              : dayjs(modified?.date_time).format(DateFormat() + " - HH:mm")}
          </span>
          <button
            disabled={downloadAlertBox}
            className="wk-download-btn"
            onClick={workupDownload}
          >
            <FileDownloadOutlinedIcon />
          </button>
          <FlyoutBox
            workupId={antigramHederData?.id}
            status={status}
            notescount={notesCount}
            handleWorkupNotesClose={handleWorkupNotesClose}
          />

          <Button
            onClick={handleSaveDraftAnalysis}
            className="wk-save-btn"
            variant="outlined"
            color="error"
            disabled={status === "COMPLETED" || enableSave}
          >
            {headersavesubmit.save}
          </Button>
          <Button
            onClick={checkSubmitConflict}
            className="wk-submit-btn"
            variant="contained"
            color="error"
            disabled={status === "COMPLETED" || apiAllData?.panels === null}
          >
            {headersavesubmit.submit}
          </Button>
        </div>
        <div className="antigram-customization-row">
          <div className="antigram-customization-toggle-wrapper">
            <CustomizationTabs
              autoControlval={autoControlval}
              panelId={panelId}
              rmToggle={rmToggle}
              ruleType={ruleType}
              handleRMToggle={handleRMToggle}
              apiData={apiAllData}
              updateRules={updateRules}
              prevApiAllData={prevApiAllData}
              classificRule={classificRule}
              onDatDataChange={handleDatDataChange}
              updateApiData={updateApiData}
              antTypeData={antTypeData}
              activeTab={activeTab}
              handleAntigenTyping={handleAntigenTyping}
              completeAlert={completeAlert}
              disableSave={disableSave}
            ></CustomizationTabs>
          </div>
        </div>
      </div>
    </div>
  );
}
