import React,{ useState } from "react";
import { Card, CardContent, CardActions, Button } from "@mui/material";
import "./DeleteWarning.scss";
import { Close, TrafficRounded, TrendingUpTwoTone } from "@mui/icons-material";
import Constants from "../../languages/language";
import './DeleteWarning.scss'
const data = Constants?.WorkupNotes;
export const DeleteWarning = (props) => {
const [isClicked,setIsClicked] = useState(false)

  const handleYesClick = () => {
    setIsClicked(true)
    props.handleDeleteWarningYes(true);
  };
  const handleNoClick = () => {
    props.handleDeleteWarningClose(true);
  };
  return (
    <>
    <div>
      <div className="card-root">
        <Card variant="outlined" className="card-section">
          <CardContent>
            <div className="warning-header">
              <p> {props.title}</p>
              <Close
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleNoClick();
                }}
              />
            </div>
            <div className="check-card">
              {props.bodyTitle} <b>{props?.sampleid}</b>
            </div>
          </CardContent>
          <CardActions className="card-action">
            <Button
              disableRipple
              className="cancel-btn"
              onClick={() => {
                handleNoClick();
              }}
            >
              {data.cancel}
            </Button>
            <Button className="accept-btn" onClick = { () => handleYesClick()} disableRipple disabled = {isClicked}>
              {props.deleteMsg}
            </Button>
          </CardActions>
        </Card>
      </div>
     </div>
    </>
  );
};
