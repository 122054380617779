import React from "react";
import { useLocation } from "react-router-dom";
import { ErrorCode } from "../../utils/icons";
import Constants from "../../languages/en.json";
import "./index.scss";
import Header from "../header/Header";
import { AppBar, Toolbar } from "@mui/material";
import { userData } from "../../views/homePageLayout/apiData";
import Sidebar from "../sidebar/Sidebar";
const Error = () => {
  const location = useLocation();
  const err_handle = Constants.errorhandler;
  {
    window.location.pathname === "/error" && <Header />;
  }
  const  status = location?.state?.status;
  const statusText = location?.state?.statusText || " ";
  const loginreload = () =>{
    window.location.reload();
  }
  return (
    <div className="homepage-layout-root">
      <div className="homapage-header">
        <Header userData={userData} />
      </div>
    <div className="homepage-main-wrapper">
        <div className="homepage-sidebar">
          <Sidebar />
        </div>
        <div className="homepage-content-wrapper">
      <Toolbar>
        <div className="error-handler">
          <ErrorCode />
          <div className="status-msg">
            {status === "404" ? err_handle.notFound : err_handle.wentWrong}
          </div>
          {status === "511" ? (<span className="login-span">{err_handle.errorlogout}<div className="login-error" onClick={loginreload}>{err_handle.login}</div>{err_handle.loginplease}</span>):("")}
          {(status !== "404" && status !== "0") && (
            <div className="error-descr">
              <span>{statusText}</span>
              <span>
                {err_handle.errCode} {status}
              </span>
            </div>
          )}
          <p>
            {status === "404" ? (
              err_handle.tryAgain
            ) : (
              <>
                {status === "0" ? err_handle.needAssistance : err_handle.needSupport }
                <span>{` ${err_handle.supportDesk}`}</span>
              </>
            )}
          </p>
        </div>
      </Toolbar>
      </div>
     </div>   
    </div>
  );
};

export default Error;
