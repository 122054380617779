import React, { useState, useEffect, useContext } from "react";
import { labSopOverview } from "../../../Redux/Actions/sopOverview";
import Constants from "../../../languages/language";
import { SpinnerContext } from "../../../context/spinner-context";
import { useNavigate } from "react-router-dom";
import i18n from "../../../i18n";
import { getStatus } from "../../../utils/global";
import "./index.scss";

const LabSopOverview = () => {
  const navigate = useNavigate();
  const labSopConsts = Constants.labSopOverview;
  const { changeSpinnerStatus } = useContext(SpinnerContext);
  const [sopData, setSopData] = useState();
  const Userlanguage = i18n.language;
  useEffect(() => {
    labSopOverviewFetch();
  }, []);

  const arrData = [];
  const labSopOverviewFetch = async () => {
    changeSpinnerStatus(true);
    const res = await labSopOverview();
    if (res?.status !== 200) {
      var err = res;
      getStatus(err,  navigate);
      changeSpinnerStatus(false);
    }
    Object.entries(res?.data).forEach(function (key, index) {
      if (Userlanguage === "fr-CA") {
        let obj = {
          value: key[1]["value"],
          name: key[1]["fr-CA"]["name"],
          description: key[1]["fr-CA"]["description"],
        };
        arrData.push(obj);
      } else {
        let obj = {
          value: key[1]["value"],
          name: key[1]["en-US"]["name"],
          description: key[1]["en-US"]["description"],
        };
        arrData.push(obj);
      }
      setSopData(arrData);
    });
    changeSpinnerStatus(false);
  };
  return (
    <div className="lab-sop-container">
      <div className="lab-sop-wrapper">
        <span className="lab-sop-heading">{labSopConsts.labSop}</span>
      </div>
      {sopData &&
        sopData.length > 0 &&
        sopData?.map((ite, idx) => {
          const value = ite.name === "Rule Out Threshold Antigen" 
                      ? Object.entries(ite.value).map(([key, value]) => `${key}: ${value}`).join(', ')
                      : ite.value

          return (
            <div className="sop-rule" key={idx}>
              <span className="rule-label">{`${ite?.name} : ${value}`}</span>
              <p className="rule-paragraph">{ite?.description}</p>
            </div>
          );
        })}
    </div>
  );
};

export default LabSopOverview;
