import * as React from "react";
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import CloseIcon from "@mui/icons-material/Close";
import "./notes.scss";
import SearchFilter from "../filter/SearchFilter";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useState, useEffect } from "react";
import { getNotes, saveNotes } from "../../Redux/Actions/notes";
import Grid from "@mui/material/Grid";
import NotesMenu from "./NotesMenu.js";
import { Done, Close } from "@mui/icons-material";
import { DeleteWarning } from "./DeleteWarning.js";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import { getUserInfo } from "../../Redux/Actions/user";
import "react-quill/dist/quill.snow.css";
import {
  updateEditedNotesContent,
  deleteNotes,
} from "../../Redux/Actions/notes";
import dayjs from "dayjs";
import { DateFormat } from "../../utils/dateFormat";
import { sanitization } from "../../utils/sanitize";
import "@progress/kendo-theme-default/dist/all.css";
import LoadingSpinner from "./LoadingSpinner";
import { CrossMask, GreenTick } from "../../utils/icons";
import Constants from "../../languages/language";
import { getStatus } from "../../utils/global";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
const stylesFont = `
  .k-content{
      font-family:"Rubik",sans-serif;
    }
`;
const { Bold, Italic, Underline, OrderedList, UnorderedList } = EditorTools;
const Notes = (props) => {
  const [inputText, setInputText] = useState("");
  const [plainInputText, setPlainInputText] = useState("");
  const [data, setData] = useState([]);
  const [olddata, setoldData] = useState([]);
  const [editingNotesId, setEditingNotesId] = useState();
  const [deletingNotesId, setDeletingNotesId] = useState();
  const [notesSubmittedSuccessFully, setNotesSubmittedSuccessfully] =
    useState();
  const [isSendClicked, setIsSendClicked] = useState(false);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [previousEditContent, setPreviousEditContent] = useState(null);
  const [newlyUpdatedText, setNewlyUpdatedText] = useState(null);
  const [isEditedNotesUpdated, setIsEditedNotesUpdated] = useState();
  const [userMailAddress, setUserMailAddress] = useState();
  const [searchText, setsrchText] = useState("");
  const [deleteNoteStatus, setdeleteNoteStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const workupID = props?.workupId;
  const warningData = Constants.WorkupNotes;
  const workupNotes = Constants.AntigenHeader;
  const waringMsg = Constants?.WorkupNotes;
  const noNotes = Constants.AntigenHeader.noNotes;
  const handleFlyOutPopUpClose = () => {
    props.handlePopupClose();
  };
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    updateNotes();
    getUserDetails();
  }, []);
  const getUserDetails = async () => {
    var res = await getUserInfo();
    setUserMailAddress(res.email);
    if (res?.status !== 200) {
      var err = res;
      getStatus(err, navigate);
    }
  };
  const updateEditNotes = (value) => {
    var valueHtml = sanitization(value.html)
    setNewlyUpdatedText(valueHtml);
    let notesContent = [...data];
    let content = notesContent.filter((x) => x.id === editingNotesId).content;
    if (previousEditContent === null) {
      setPreviousEditContent(content);
    }
    notesContent
      .filter((x) => x.id === editingNotesId)
      .map((x) => (x.content = valueHtml));
    setData([...notesContent]);
    setoldData([...notesContent]);
  };
  const sendNotes = async () => {
    setIsLoading(true);
    setIsSendClicked(true);
    const sendNotesObj = {
      content: inputText,
    };
    const res = await saveNotes(workupID, sendNotesObj);
    setNotesSubmittedSuccessfully(true);
    if(res?.status !== 200)
    {
      var err = res;
      getStatus(err, navigate);
    }
    setInputText("");
    setPlainInputText("");
    setIsLoading(false);
  };
  useEffect(() => {
    if (notesSubmittedSuccessFully && isSendClicked) {
      updateNotes();
      setIsSendClicked(false);
      setNotesSubmittedSuccessfully(false);
    }
  }, [notesSubmittedSuccessFully]);
  const updateNotes = async () => {
    const res = await getNotes(workupID);
    setData(res);
    setoldData(res);
    if (res?.status !== 200) {
      var err = res;
      getStatus(err, navigate);
    }
  };
  const handleOnClick = (value, index) => {
    if (value === "Edit") {
      setEdit(true);
      setEditingNotesId(index);
      let oldNotesData = data.filter((x) => x.id === index);
      setPreviousEditContent(oldNotesData[0].content);
    } else if (value === "Delete") {
      setIsLoading(true);
      setDeletingNotesId(index);
      setIsDeleteClicked(true);
      setIsLoading(false);
    }
  };
  const handleOnClose = () => {};
  const handleDeleteWarningYes = () => {
    setIsDeleteClicked(false);
    deleteNote(workupID, deletingNotesId);
  };
  const deleteNote = async (workupID, index) => {
    setIsLoading(true);
    const res = await deleteNotes(workupID, index);
    setdeleteNoteStatus(true);
    setDeletingNotesId(0);
    updateNotes();
    if (res?.status !== 200) {
      getStatus(res, navigate);
    }
    setIsLoading(false);
  };
  const updateSelectedNotes = async (workupID, index) => {
    const sendNotesObj = {
      content: newlyUpdatedText,
    };
    if (sendNotesObj.content) {
      const res = await updateEditedNotesContent(workupID, index, sendNotesObj);
      if(res?.status !== 200)
      {
        var err = res;
        getStatus(err, navigate);
      }
      setEditingNotesId(0);
      setPreviousEditContent(null);
      setNewlyUpdatedText(sendNotesObj);
      setIsEditedNotesUpdated(true);
    }
  };
  useEffect(() => {
    if (isEditedNotesUpdated) {
      setIsEditedNotesUpdated(false);
      updateNotes();
    }
  }, [isEditedNotesUpdated]);
  const handleDeleteWarningClose = () => {
    setIsDeleteClicked(false);
    setDeletingNotesId(0);
  };
  const handleEditCancel = (index) => {
    let newdata = data;
    newdata.map((x) => {
      if (x.id === index) {
        x.content = previousEditContent;
      }
    });
    setData(newdata);
    setEditingNotesId(0);
    setNewlyUpdatedText("");
    setPreviousEditContent(null);
  };
  const handleEditSubmit = () => {
    updateSelectedNotes(workupID, editingNotesId);
    setEditingNotesId(0);
  };
  const populateNewComment = (event) => {
    if (props?.status !== "COMPLETED") {
      setInputText(event.html);
      var plainText = event.html.replace(/<[^>]*>/g, "");
      plainText = sanitization(plainText)
      setPlainInputText(plainText);
    }
  };
  const handletextChange = (enteredText) => {
    setsrchText(enteredText);
  };
  useEffect(() => {
    let notesList = olddata;
    if (searchText && searchText.length > 0) {
      notesList = notesList.filter(function (x) {
        let name =
          x?.created?.first_name + " " + x?.created?.last_name.toLowerCase().trim();
        return (
          x.content
            .toLowerCase()
            .trim()
            .includes(searchText.toLowerCase().trim()) ||
          x.created.last_name
            .toLowerCase()
            .trim()
            .includes(searchText.toLowerCase().trim()) ||
          x.created.first_name
            .toLowerCase()
            .trim()
            .includes(searchText.toLowerCase().trim()) ||
          name.toLowerCase().includes(searchText.toLowerCase()) ||
          new Date(x.created.date_time)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .toLowerCase()
            .includes(searchText)
        );
      });
      setData(notesList);
    } else {
      setData(olddata);
    }
  }, [searchText]);
  const handleDeleteMessageClose = () => {
    setdeleteNoteStatus(false);
  };
  const fontEditor = (event) => {
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement("style");
    style.appendChild(iframeDocument.createTextNode(stylesFont));
    iframeDocument.body.appendChild(style);
  };
  return (
    <div>
      {deleteNoteStatus === true ? (
        <div className="deletenote-success-message">
          <GreenTick width={25} height={25} />
          <b>{warningData.note}&nbsp;:</b>
          {warningData.message}
          <div
            style={{ cursor: "pointer", marginLeft: "auto" }}
            onClick={handleDeleteMessageClose}
          >
            <CrossMask width={15} height={15} />
          </div>
        </div>
      ) : (
        ""
      )}
      {deletingNotesId > 0 ? (
        <DeleteWarning
          title={waringMsg.deleteLogo}
          bodyTitle={waringMsg.title}
          deleteMsg={waringMsg?.accept}
          handleDeleteWarningClose={(value) => {
            handleDeleteWarningClose(value);
          }}
          handleDeleteWarningYes={(value) => {
            handleDeleteWarningYes(value);
          }}
        />
      ) : (
        ""
      )}
      <div className="notes-header">
        <span>{workupNotes.workupNotes}</span>&nbsp;&nbsp;
        <div className="notes-icons">
          <CloseIcon onClick={() => handleFlyOutPopUpClose()} />
        </div>
      </div>
      {props.status !== "COMPLETED" ? (
        <div className="notes-editor-wrapper">
          <Editor
            className="Flyout-root"
            tools={[[Bold, Italic, Underline, OrderedList, UnorderedList]]}
            contentStyle={{
              width: "100%",
            }}
            onMount={fontEditor}
            value={inputText}
            onChange={(e) => populateNewComment(e)}
          />
          <div
            className="notes-save"
            onClick={sendNotes}
            disabled={plainInputText.length > 0 ? "" : "disbaled"}
          >
            <h3>{workupNotes.save}</h3>
            <SendOutlinedIcon className="save-btn" />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="notes-section">
        <div className="workup-root">
          <div className="left-sec">
            <SearchFilter
              className="search-filter"
              searchText={searchText}
              onSearchTextChange={(value) => handletextChange(value)}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <LoadingSpinner className="loading-spinner-flyout" />
      ) : (
        <div className="notes-content">
          {data && data?.length < 1 ? (
            <div className="noNotes">{noNotes}</div>
          ) : (
            <Grid container spacing={1} key="">
              {data && data.length > 0
                ? data.map((x, index) => (
                    <div key={x.id} className="notes-bg">
                      <div className="name-dis">
                        <div className="fName-lName" item xm={5}>
                          {x?.created?.first_name + " " + x?.created?.last_name}
                        </div>
                        <span className="date-time" item xl={5}>
                          {dayjs(
                            x?.modified?.date_time
                              ? x?.modified?.date_time
                              : x?.created?.date_time
                          ).format(DateFormat() + " - h:mm A")}
                        </span>

                        <Grid
                          item
                          xm={1}
                          style={{ position: "relative", left: "2.8rem" }}
                        >
                          {x?.created &&
                          x?.created?.email === userMailAddress &&
                          props?.status !== "COMPLETED" ? (
                            <NotesMenu
                              className="note-menu"
                              handleOnClick={(value) =>
                                handleOnClick(value, x.id)
                              }
                              handleOnClose={() => handleOnClose()}
                            />
                          ) : (
                            ""
                          )}
                        </Grid>
                      </div>
                      <Grid item xs={12} style={{ height: "fitContent" }}>
                        {editingNotesId === x.id ? (
                          <>
                            <Editor
                              className="Flyout-comment"
                              onMount={fontEditor}
                              tools={[
                                [
                                  Bold,
                                  Italic,
                                  Underline,
                                  OrderedList,
                                  UnorderedList,
                                ],
                              ]}
                              contentStyle={{
                                width: "100%",
                                opacity: 8,
                              }}
                              value={x.content}
                              onChange={(e) => updateEditNotes(e)}
                            />
                            <div className="action-strap">
                              <Close
                                className="icons"
                                onClick={() => handleEditCancel(x.id)}
                              />
                              <Done
                                id="done-icon"
                                className="icons"
                                onClick={() => handleEditSubmit()}
                              />
                            </div>
                          </>
                        ) : (
                          <ReactQuill
                            theme={false}
                            defaultValue={x.content}
                            style={{ minHeight: "fitContent" }}
                            readOnly={true}
                          />
                        )}
                      </Grid>
                    </div>
                  ))
                : ""}
            </Grid>
          )}
        </div>
      )}
    </div>
  );
};
export default Notes;
