import * as React from 'react';
import { useEffect } from "react";
import { Auth } from "aws-amplify/lib-esm";
import { Card, CardContent, CardActions, Button } from "@mui/material";
import "./SessionTimeoutDialog.scss"
import Constants from "../../languages/language"
import ModalComponent from '../modal/Modal';
import { Close } from "@mui/icons-material";
import {AUTH_PROVIDER} from "../../utils/constants";

export default function SessionTimeout({ open, onLogout, onContinue,
    extendSessionCheck }) {
    const constant = Constants?.extendSession?.firstModal
    const secondConstant = Constants?.extendSession?.secondModal
    const [seconds, setSeconds] = React.useState(0);
    const [minutes, setMinutes] = React.useState(5);

    const extendSession = () =>{
        onContinue()
        extendSessionCheck()
    }
    useEffect(() => {
        if (open) {
            let sampleInterval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(sampleInterval);
                        localStorage?.setItem("sessionTimeout","false")
                        Auth?.signOut({ provider: AUTH_PROVIDER })
                        window?.location?.reload()
                    } else {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    }
                }
            }, 1000);
            return () => {
                clearInterval(sampleInterval);
            };
        }
        else {
            setSeconds(0)
            setMinutes(5)
        }
    },[seconds])

    return (
        <div>
            <ModalComponent anchorEl={Math.random()} open={open}>
                <div className="card-root">
                    <Card variant="outlined" className="card-content">
                        <CardContent>
                            <div className="session-header">
                                <p>{constant?.heading}</p>
                                <Close
                                    className="close-icon"
                                    onClick={onContinue}
                                />
                            </div>
                            <div className="session-content">
                                {constant?.preTimerContent} {<span className='timer'>
                                 {minutes.toLocaleString(undefined,{minimumIntegerDigits: 2})
                                  + " : " + seconds.toLocaleString(undefined,{minimumIntegerDigits: 2})}
                                  </span>} {constant?.postTimerContent}
                                <p>
                                    {constant?.confirm}</p>
                            </div>
                        </CardContent>
                        <CardActions className="session-action">
                            {<Button
                                className="logout-btn"
                                onClick={onLogout}
                            >
                                {constant?.logout}
                            </Button>}
                            <Button className="extend-btn" onClick={extendSession}>
                                {constant?.extendSessionBtn}
                            </Button>
                        </CardActions>
                    </Card>
                </div>
            </ModalComponent>
        </div>

    );
}



