import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CheckIcon from "@mui/icons-material/Check";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { styled, alpha } from "@mui/material/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./index.scss";
import ClassificationRow from "./ClassificationRow/ClassificationRow";
import AutoControl from "../autoControl/AutoControl";
import AntigenTyping from "./antigenTyping/AntigenTyping";
import DatResults from "./datResult/DatResults";
import PositionedSnackbar from "../../SnackbarPopup/SnackbarPopup";
import WarningIcon from "@mui/icons-material/Warning";
import Constants from "../../../languages/language";

const datTypes = Constants.antigramTable.workupHeader.DAT.types;
const antTypConsts = Constants.antigramTable.workupHeader.antigenTyping;
const analyzerMessage = Constants.antigramTable.workupHeader.DAT.analyzerAlert;
const anitigenHeader = Constants.AntigenHeader;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const isDataFromAnalyser = (datData) => {
  datData = datData || {};
  let isFromAnalyser = false;
  datTypes.forEach((key) => {
    let fieldData = datData[key];
    if (
      fieldData &&
      (!fieldData.grade?.user_entered || !fieldData.mf?.user_entered)
    ) {
      isFromAnalyser = true;
    }
  });
  return isFromAnalyser;
};

const checkDatHasUserEnteredValue = (datData) => {
  datData = datData || {};
  let result = false;

  datTypes.forEach((key) => {
    let fieldData = datData[key];
    if (
      fieldData &&
      ((fieldData.grade.value != null &&
        fieldData.grade.value !== "NT" &&
        fieldData.grade?.user_entered) ||
        fieldData.mf.value !== false ||
        (null && fieldData.mf?.user_entered))
    ) {
      result = true;
    }
  });

  return result;
};

const checkPositiveOrNegative = (datData) => {
  datData = datData || {};
  let result = false;

  const fieldDataItems = datTypes
    .map((key) => datData[key])
    .filter((f) => f != null);

  // if any positive is there return 1
  if (
    fieldDataItems.find(
      (f) =>
        f.value != null && f.value !== "NT" && f.value !== "0"
    )
  ) {
    return 1;
  }

  if (fieldDataItems.find((f) => f.value === "0")) {
    return -1;
  }
};

/* Commented recommonded change indicator code for future re-use */
// const getChangedClassifications = (apiData, prevApiData) => {
//   if (!prevApiData) {
//     return [];
//   }
//   const algoSol = apiData.classifications?.algorithm_solution || {};
//   const prevAlgoSol = prevApiData?.classifications?.algorithm_solution || {};

//   const ruleOutChanges = getChanges(
//     algoSol.ruled_out || [],
//     prevAlgoSol.ruled_out
//   );

//   return ruleOutChanges;
// };

// const getChanges = (list1, list2) => {
//   if (list1 !== null && list2 !== null)
//     return [
//       ...list1.filter((i) => list2?.indexOf(i) === -1),
//       ...list2.filter((i) => list1?.indexOf(i) === -1),
//     ];
// };

export default function BasicTabs({
  apiData,
  prevApiAllData,
  rmToggle,
  ruleType,
  updateRules,
  handleRMToggle,
  classificRule,
  panelId,
  onDatDataChange,
  antTypeData,
  handleAntigenTyping,
  activeTab,
  completeAlert,
  disableSave,
  autoControlval
}) {
  const [value, setValue] = React.useState(0);
  const [ruledClassificObj, setRuledClassificObj] = React.useState();
  const [showAlertMsg, setShowAlertMsg] = React.useState(false);
  const [changedClassifications, setChangedClassifications] = useState([]);
  const [incomingChangedClassific, setIncomingChangedClassific] =
    useState(false);
  const [datResultAnalyzAlertData, setDatResultAnalyzAlertData] = useState({
    isDatDataFromAnalyser: false,
    showDatDataFromAnlzAlert: false,
  });

  useEffect(() => {
    setValue(activeTab);
  }, [activeTab]);

  const [datResultData, setDatResultData] = useState({
    disabledDropdowns: [],
    datData: {},
  });
  const [antTypeVal, setAntTypeVal] = React.useState();
  const [datResultDataState, setDatResultDataState] = useState(0); // -1(negative), 0(neutral), 1(positive)

  useEffect(() => {
    setAntTypeVal(antTypeData);
  }, [antTypeData]);

  useEffect(() => {
    const workup = apiData?.workup;
    const apiDatData = workup?.dat;
    const newDatData = {};
    const disabledFields = [];

    if (apiDatData) {
      // Check if analyser data changed
      const isDatDataFromAnalyser = isDataFromAnalyser(apiDatData);
      const showDatDataFromAnlzAlert =
        isDatDataFromAnalyser &&
        !sessionStorage.getItem(workup.id + "-datresultalertshown");

      // need to show the alert only once in the entire session
      sessionStorage.setItem(workup.id + "-datresultalertshown", true);

      setDatResultAnalyzAlertData({
        isDatDataFromAnalyser,
        showDatDataFromAnlzAlert,
      });

      datTypes.forEach((key) => {
        const apiDatFieldData = apiDatData[key];

        if (apiDatFieldData) {
          newDatData[key] = {
            value: apiDatFieldData.grade.value,
            mf: apiDatFieldData.mf.value,
          };

          if (
            apiDatFieldData.grade.user_entered === false ||
            apiDatFieldData.mf.user_entered === false
          ) {
            disabledFields.push(key);
          }
        }
      });
    }

    setDatResultData({
      disabledDropdowns: disabledFields,
      datData: newDatData,
    });
    onDatDataChange(newDatData);
    const resultState = checkPositiveOrNegative(newDatData);
    setDatResultDataState(resultState);
  }, [apiData.workup?.dat]);

  const handleDatDataChange = (data) => {
    setDatResultData({
      ...datResultData,
      datData: data,
    });

    updateDatPositiveOrNegativeState(data);
    onDatDataChange(data);
  };

  useEffect(() => {
    autoControlAnalyser();
  }, []);
  const [autoUserEnter, setAutoUserEnter] = useState(false);
  const autoControlAnalyser = () => {
    if (
      apiData &&
      apiData?.panels != null &&
      apiData?.panels[0] != null &&
      apiData?.panels[0]?.results != null
    ) {
      setAutoUserEnter(apiData?.panels[0]?.results[11]?.user_entered);
    }
  };

  useEffect(() => {
    setRuledClassificObj(classificRule);
  }, [classificRule]);

  /* Commented recommonded change indicator code for future re-use */
  // useEffect(() => {
  //   const changedClassifications = getChangedClassifications(
  //     apiData,
  //     prevApiAllData
  //   );
  //   setChangedClassifications(changedClassifications);
  //   if (prevApiAllData !== null) {
  //     // JSON.stringify(apiData.alternate_classifications) !== JSON.stringify(prevApiAllData.alternate_classifications) ? setIncomingChangedClassific(true) : "";
  //     JSON.stringify(apiData?.classifications?.algorithm_solution) !==
  //       JSON.stringify(prevApiAllData?.classifications?.algorithm_solution)
  //       ? setIncomingChangedClassific(true)
  //       : "";
  //   }
  // }, [apiData, prevApiAllData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Toggle button code
  const [slectedView, setSlectedView] = React.useState("recommended");

  useEffect(() => {
    if (apiData?.workup?.user_analyzed) {
      setSlectedView("manual");
      handleRMToggle(true);
    }

    else {
      setSlectedView("recommended");
      handleRMToggle(false);
    }
  }, []);

  useEffect(() => {
    rmToggle && setSlectedView("manual");
  }, [rmToggle])

  const handleToggleChange = (viewMode) => {
    if (viewMode !== null && viewMode === "recommended") {
      setSlectedView(viewMode);
      handleRMToggle(false);
      setIncomingChangedClassific(false);
    } else if (viewMode !== null && viewMode === "manual") {
      setSlectedView(viewMode);
      handleRMToggle(true);
    }
    setValue(0);
  };

  // Keeping commented code for future requirements
  //Rules dropdown button code
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   handleToggleChange("recommended");
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const updatedRuleTypeDefault = () =>{
  //   updateRules("3 x 3");
  //   handleClose();
  // }
  // const updatedRuleType = () =>{
  //   updateRules("2 x 2");
  //   handleClose();
  // }

  //Accordion state change
  const [accOpen, setAccOpen] = React.useState(true);
  
  const [showDiscrepencyMsg, setShowDiscrepencyMsg] = React.useState(false);

  const updateDatPositiveOrNegativeState = (datData) => {
    const resultState = checkPositiveOrNegative(datData);
    setDatResultDataState(resultState);
  };

  /* Update Antigen Typing obj */
  let antTypeValOrgObj = apiData?.workup?.antigen_typing;
  const updateAntigramTyping = (type, currentCell, typingVal) => {
    setShowDiscrepencyMsg(false);
    const temp = antTypeVal[currentCell];
    let antTypeObjTemp = { ...antTypeVal };
    if (type === "remove") {
      antTypeObjTemp = {
        ...antTypeObjTemp,
        [currentCell]: { ...temp, ["grade"]: null },
      };
    } else {
      if(apiData?.classifications?.algorithm_solution?.ruled_in?.indexOf(currentCell) > -1 && (typingVal === "1+" || typingVal === "2+" || typingVal === "3+" || typingVal === "4+")){
        setShowDiscrepencyMsg(true);
      }
      antTypeObjTemp = {
        ...antTypeObjTemp,
        [currentCell]: { ...temp, ["grade"]: typingVal },
      };
    }
    handleAntigenTyping(antTypeObjTemp);
  };
  const updateMfTagsParent = (type, currentCell) => {
    const temp = antTypeVal[currentCell];
    let antTypeObjTemp = { ...antTypeVal };
    if (type === "remove") {
      antTypeObjTemp = {
        ...antTypeObjTemp,
        [currentCell]: { ...temp, ["mf"]: false },
      };
    } else {
      antTypeObjTemp = {
        ...antTypeObjTemp,
        [currentCell]: { ...temp, ["mf"]: true },
      };
    }
    handleAntigenTyping(antTypeObjTemp);
  };

  const analyzerMeassage = () => {
    return (
      <React.Fragment>
        <WarningIcon className="warning-icon" style={{ color: "#F2A900" }} />
        <span className="alert-note">
          <strong>{antTypConsts.changeAlertTitle}:</strong> {analyzerMessage}
        </span>
      </React.Fragment>
    );
  };

  const handleDatResultAlertClose = () => {
    setDatResultAnalyzAlertData({
      ...datResultAnalyzAlertData,
      showDatDataFromAnlzAlert: false,
    });
  };

  useEffect(() => {
    checkAntigenTypeIndicator();
  }, [antTypeVal]);

  let discrepencyIndicator = "";
  const [reccmdDiscrepencyDot, setReccmdDiscrepencyDot] = React.useState();
  const [antiTypeIndicator, setAntiTypeIndicator] = React.useState();
  const checkAntigenTypeIndicator = () => {
    setAntiTypeIndicator("");
    for(let key in antTypeVal){
      if(antTypeVal[key].grade === "1+" || antTypeVal[key].grade === "2+" || antTypeVal[key].grade === "3+" || antTypeVal[key].grade === "4+"){
        setAntiTypeIndicator(<AddCircleRoundedIcon />);
        if(apiData?.classifications?.algorithm_solution?.ruled_in?.indexOf(key) > -1){
          discrepencyIndicator = "rec-discrepency";
        }
        break;
      }else if(antTypeVal[key].grade === "0"){
        setAntiTypeIndicator(<RemoveCircleRoundedIcon />);
      }
    }
    setReccmdDiscrepencyDot(discrepencyIndicator);
    return antiTypeIndicator;
  };

  const closeDiscrepencyMsg = () => {
    setShowDiscrepencyMsg(false);
  }

  const [positiveAutoControl, setPositiveAutoControl] = useState(0)
  const [negativeAutoControl, setNegativeAutoControl] = useState(0)
  const gradeArr = ["1+", "2+", "3+", "4+"]
  const negativeArr = ["0", "NT", null]
  const notTestedArr = ["NT", null]

  let negativeCheck = 0;
  let notTestedCheck = 0;
  const panelLength = apiData?.panels?.length

  const updateIndicator = (val) => {
    useEffect(() => {
      negativeCheck = 0;
      notTestedCheck = 0;
      let autoControlCols;
      let autoControlIndex = null
      for(let j = 0; j < apiData?.panels[val]?.results?.length; j++){
        if(apiData.panels[val]?.results[j].cell_number === "AC"){
          autoControlIndex = j;
          break;
        }
      }
      autoControlCols = apiData?.panels[val]?.results[autoControlIndex]?.columns

      /*checking the autoControl values are negative or null */
      for (let i = 0; i < panelLength; i++) {
        let panelIndex = []
      for(let j = 0; j < apiData?.panels[i]?.results?.length; j++){
        if(apiData.panels[i]?.results[j].cell_number === "AC"){
          panelIndex = j;
          break;
        }
      }
        let panelType = apiData?.panels[i]?.results[panelIndex]?.columns

        if (negativeArr?.includes(panelType?.fic?.grade) &&
          negativeArr?.includes(panelType?.iat?.grade) &&
          negativeArr?.includes(panelType?.is?.grade) &&
          negativeArr?.includes(panelType?.fifth_column?.grade) &&
          negativeArr?.includes(panelType?.["37c"]?.grade)) {
          negativeCheck = negativeCheck + 1
        }
        if (notTestedArr?.includes(panelType?.fic?.grade) &&
          notTestedArr?.includes(panelType?.iat?.grade) &&
          notTestedArr?.includes(panelType?.is?.grade) &&
          notTestedArr?.includes(panelType?.fifth_column?.grade) &&
          notTestedArr?.includes(panelType?.["37c"]?.grade)) {
          notTestedCheck = notTestedCheck + 1
        }
      }
      if (negativeCheck === panelLength) {
        setPositiveAutoControl(0)
      }
      if (notTestedCheck === panelLength) {
        setPositiveAutoControl(0)
        setNegativeAutoControl(0)
      }

      /*checking autocontrol values are positive if they are not negative or null*/
      if (negativeCheck !== panelLength && notTestedCheck !== panelLength) {
        if (gradeArr?.includes(autoControlCols?.fic?.grade) ||
          gradeArr?.includes(autoControlCols?.iat?.grade) ||
          gradeArr?.includes(autoControlCols?.is?.grade)
          || gradeArr?.includes(autoControlCols?.fifth_column?.grade) ||
          gradeArr?.includes(autoControlCols?.["37c"]?.grade)) {
          setPositiveAutoControl(positiveAutoControl + 1)
        }
      }

      autoControlCols?.fic?.grade === "0" || autoControlCols?.iat?.grade === "0" ||
        autoControlCols?.is?.grade === "0" || autoControlCols?.fifth_column?.grade === "0"
        || autoControlCols?.["37c"]?.grade === "0" ? setNegativeAutoControl(negativeAutoControl + 1) : null

    }, [autoControlval])

  }
  const checkAutoControlIndicator = () => {
    let autoControlIndicator;

    for (let i = 0; i < panelLength; i++) {
      updateIndicator(i)
    }
    if (positiveAutoControl) {
      autoControlIndicator = <AddCircleRoundedIcon />;
    }
    else if (negativeAutoControl && positiveAutoControl === 0) {
      autoControlIndicator = <RemoveCircleRoundedIcon />;
    }
    return autoControlIndicator
  }

  const getDatResultIcon = () => {
    if (datResultDataState === 1) {
      return <AddCircleRoundedIcon/>;
    }

    if (datResultDataState === -1) {
      return <RemoveCircleRoundedIcon />;
    }

    if (datResultDataState === 0) {
      return "";
    }
  };

  //apiData.workup.dat.poly.grade.user_entered = false;

  return (
    <div className="cust-box-wrapper">
      {/* Commented For Future Use */}
      {/* {datResultAnalyzAlertData.showDatDataFromAnlzAlert && value === 3 && (
        <PositionedSnackbar
          fwdStyles={{
            color: "#34383C",
            backgroundColor: "#FFF2D0",
            borderColor: "#F2A900",
          }}
          onClose={handleDatResultAlertClose}
        >
          {analyzerMeassage()}
        </PositionedSnackbar>
      )} */}

      <div className="tab-row-wrapper">
        <div className="option-sec">
          <span className="toggle-btn">
            <ButtonGroup aria-label="Platform">
              <Button
                className={`btn-rec ${reccmdDiscrepencyDot} ${slectedView === "recommended" ? "selected" : ""}`}
                onClick={() => handleToggleChange("recommended")}
              >
                {anitigenHeader.recommended}{" "}
                {/* {incomingChangedClassific && <ErrorOutlineIcon />} */}
              </Button>
              {/* // Keeping commented code for future requirements
              <Button
                className={`btn-dropdown ${
                  slectedView === "recommended" ? "selected" : ""
                }`}
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="outlined"
                disableElevation
                onClick={handleClick}
                disabled={apiData?.panels === null}

              >
                <span className="rule-badge">{<KeyboardArrowDownIcon />}</span>
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                className="rule-dropdown"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <li
                  className={
                    ruleType === "3 x 3" ? `active dropdn-list` : "dropdn-list"
                  }
                  onClick={updatedRuleTypeDefault}
                >
                  {" "}
                  3 X 3{" "}
                  {ruleType === "3 x 3" && (
                    <span>
                      <CheckIcon />
                    </span>
                  )}
                </li>
                <li
                  className={
                    ruleType === "2 x 2" ? `active dropdn-list` : "dropdn-list"
                  }
                  onClick={updatedRuleType}
                >
                  {" "}
                  2 X 2{" "}
                  {ruleType === "2 x 2" && (
                    <span>
                      <CheckIcon />
                    </span>
                  )}
                </li>
              </StyledMenu> */}
              <Button
                className={`btn-manual ${slectedView === "manual" ? "selected" : ""
                  }`}
                onClick={() => handleToggleChange("manual")}
              >
                {anitigenHeader.manual}
              </Button>
            </ButtonGroup>
          </span>
        </div>
        <Tabs
          className="tabs-group"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            className="clasification-tab"
            label="Classifications"
            {...a11yProps(0)}
            disabled={apiData?.panels === null}
          />
          <Tab
            label={anitigenHeader.antigenTypin}
            icon={antiTypeIndicator}
            iconPosition="end"
            {...a11yProps(1)}
          />
          <Tab
            label={anitigenHeader.autocontrol}
            icon={checkAutoControlIndicator()}
            iconPosition="end"
            {...a11yProps(2)}
          />
          <Tab
            icon={getDatResultIcon()}
            iconPosition="end"
            label={anitigenHeader.dat}
            {...a11yProps(3)}
          />
        </Tabs>
        <AccordionSummary
          onClick={() => {
            setAccOpen(!accOpen);
          }}
          expandIcon={
            (!accOpen && <ExpandMoreIcon />) || (accOpen && <ExpandLessIcon />)
          }
          aria-controls="panel1a-content"
          id="panel1a-header1"
        ></AccordionSummary>
      </div>

      {accOpen && (
        <Accordion>
          <AccordionDetails>
            <TabPanel className="classifications-tab" value={value} index={0}>
              <ClassificationRow
                ruledClassificObj={ruledClassificObj}
                changedClassifications={changedClassifications}
                ruleType={ruleType}
                slectedView={slectedView}
                antTypeVal={antTypeVal}
              />
            </TabPanel>
            <TabPanel value={value} index={1} className="antigenTyping-tab-wrapper">
              <AntigenTyping
                className="antigenTyping-tab"
                getAntTypeVal={antTypeVal}
                antTypeValOrgObj={antTypeValOrgObj}
                updateAntigramTyping={updateAntigramTyping}
                updateMfTagsParent={updateMfTagsParent}
                apiData={apiData}
                completeAlert={completeAlert}
                disableSave={disableSave}
              />
              {showDiscrepencyMsg && <PositionedSnackbar onClose={()=>closeDiscrepencyMsg()}><WarningIcon className='warning-icon' style={{color: "#F2A900"}} /><span className='alert-note'><strong>{antTypConsts.discrepencyAlertTitle}:</strong> {antTypConsts.discrepencyAlertMsg}</span></PositionedSnackbar>}
            </TabPanel>
            <TabPanel className="autocontol-tab" value={value} index={2}>
              <AutoControl apiData={apiData} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <DatResults
                onDatDataChange={handleDatDataChange}
                datResultData={datResultData}
                workupStatus={apiData.workup?.status}
                completeAlert={completeAlert}
                disableSave={disableSave}
              />
            </TabPanel>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}
