import React, { useEffect, useState, useContext } from "react";
import MenuDropdown from "../menuDropdown/MenuDropDown";
import HeaderMenuDropDown from "../menuDropdown/HeaderMenuDropDown";
import AlertNote from "../AlertNotification/AlertNote";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Constants from "../../../languages/language";
import dayjs from "dayjs";
import UndoIcon from "@mui/icons-material/Undo";
import "./index.scss";
import RedoIcon from "@mui/icons-material/Redo";
import ViewPanelDetails from "../../panelDetails/ViewPanelDetails";
import { getPanelDetails } from "../../../Redux/Actions/results.js";
import FormattedLabel from "../../formattedLabel/FormattedLabel";
import AutoControlDropDown from "../autoControl/autoControlDropDown/AutoControlDropDown";
import PositionedSnackbar from "../../SnackbarPopup/SnackbarPopup";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { DateFormat } from "../../../utils/dateFormat";
import { getNotes } from "../../../Redux/Actions/panelnotes";
import { useNavigate } from "react-router-dom";
import NameChangeDrawer from "../nameChangeDrawer/NameChangeDrawer.js";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
import { WorkupIinterpretationData } from "../../../context/workup-interpretation.js";
import Button from "@mui/material/Button";
import { FlyoutBox } from "../../Notes/Flyout";
import { DeleteWarning } from "../../Notes/DeleteWarning";
import { col5Name } from "../../../Redux/Actions/workups";
import { getStatus } from "../../../utils/global";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Popper } from "@mui/material";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "#34383C",
    fontWeight: 400,
    padding: 12,
    borderRadius: 6,
    fontSize: 12,
    filter: "drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.08))",
  },
}));

const AntigramTable = ({
  apiAllData,
  rmToggle,
  ruleType,
  handleRMToggle,
  handleRuleUpdates,
  panelExpand,
  panelControl,
  completeAlert,
  enableSave,
  disableSave,
  handleAutoControlIndicator,
  downloadWorkupPanelOrder
}) => {
  const antTypConsts = Constants.antigramTable.workupHeader.antigenTyping;
  const undoredoclearWarMsg = Constants?.clearUndoRedo;
  const fifthColContent = Constants?.fifthCol?.hoverContent
  const statusCompleted =
    apiAllData?.workup?.status === "COMPLETED" ? true : false;
  const navigate = useNavigate();
  {
    /* AutoControl Alert message Code Commented for Future Use */
  }
  // const warningMessage = () => {
  //   return (
  //     <React.Fragment>
  //       <WarningIcon className="warning-icon" style={{ color: "#F2A900" }} />
  //       <span className="alert-note">
  //         <strong>{antTypConsts.changeAlertTitle}:</strong>{" "}
  //         {antTypConsts.changeAlertMessage}
  //       </span>
  //     </React.Fragment>
  //   );
  // };
  const [openPanel, setOpenPanel] = useState(false);
  const [isNameChangeDrawerOpen, setIsNameChangeDrawerOpen] = useState(false);
  const [nameChngPanelIndex, setNameChngPanelIndex] = useState();
  const [selectedPanelIndex, setSelectedPanelIndex] = useState(-1);
  const [clearStateUpdate, setClearStateUpdate] = useState(false)
  const [fifthColumnData, setFifthColumnData] = useState(
    (apiAllData.panels || []).map((p) => ({ ...(p.fifth_column || {}) }))
  );
  let clearPanels = false;
  let updatepanels;
  let updatepanelsDetails;
  let undefinedErr = {status: 400}

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const handleClickPopper = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };
  const popupState = Boolean(anchorEl2);
  const handleClickAway = () => {
    setAnchorEl2(false);
  };

  const [anchorEl22, setAnchorEl22] = React.useState(null);
  const handleClickPopper2 = (event) => {
    setAnchorEl22(anchorEl22 ? null : event.currentTarget);
  };
  const popupState2 = Boolean(anchorEl22);
  const handleClickAway2 = () => {
    setAnchorEl22(false);
  };




  const handlePanelEditName = (index) => {
    if (statusCompleted) {
      completeAlert();
    } else {
      setIsNameChangeDrawerOpen(!isNameChangeDrawerOpen);
      setNameChngPanelIndex(index);
    }
  };

  const handleNameChangeDrawer = async (data) => {
    const res = await col5Name(
      apiAllData.workup.id,
      apiAllData.panels[nameChngPanelIndex]?.panel?.id,
      {
        e_tag: apiAllData.e_tag,
        test_type: data.columnName,
        enzyme: data.enzyme,
      }
    );
    apiAllData.e_tag = res?.data?.e_tag;
    const newFifthColumnData = [...fifthColumnData];
    newFifthColumnData[nameChngPanelIndex] = {
      test_type: data.columnName,
      enzyme: data.enzyme,
    };
    if(res?.status !== 200)
    {
      var err = res;
      getStatus(err, navigate);
    }
    setFifthColumnData(newFifthColumnData);

  };
  const handleNameChangeDrawerClose = () => {
    setIsNameChangeDrawerOpen(false);
  };

  const [load, setLoad] = useState(false);

  const ViewDetails = async () => {
    setLoad(true);
    let panelId = apiAllData?.panels[selectedPanelIndex]?.panel?.id;
    let workupId = apiAllData?.workup?.id
    const res = await getPanelDetails(workupId,panelId);
    if (res) {
      const responseData = res;
      setPanelApiResponse(responseData);
    }
    if (res?.status !== 200) {
      var err = res;
      getStatus(err, navigate);
    }
    setLoad(false);
  };
  const handleOpen = (key) => {
    setSelectedPanelIndex(key);
    setOpenPanel(true);
  };
  const [notesCount, SetNotesCount] = useState();

  useEffect(() => {
    if (openPanel) {
      ViewDetails();
    }
  }, [openPanel]);

  const panelClose = () => {
    setOpenPanel(false);
  };
  const [panelApiResponse, setPanelApiResponse] = useState();

  const antigramTableConst = Constants.antigramTable;
  const [localApiAllData, setLocalApiAllData] = useState({ ...apiAllData });

  useEffect(() => {
    updateData();
  }, [rmToggle, ruleType]);

  useEffect(() => {
    setLocalApiAllData({ ...apiAllData });
  }, [apiAllData]);

  const [context, setContext] = useContext(WorkupIinterpretationData);
  /* Re-render page with toglle of manual recommonded mode */
  const updateData = () => {
    rldClassification();
    /* First time when user switch to manual mode for the new workup dumping all recommonded data to manual */
    let initClassific;
    apiAllData?.classifications?.user_solution?.ruled_in?.length === 0 &&
    apiAllData?.classifications?.user_solution?.ruled_out?.length === 0 &&
    apiAllData?.classifications?.user_solution?.unknown?.length === 0
      ? (initClassific = true)
      : (initClassific = false);

    if (apiAllData?.workup?.user_analyzed === false && initClassific === true) {
      // Keeping rules commented code for future requirements
      // if (ruleType === "3 x 3") {
      //   apiAllData.classifications.user_solution = JSON.parse(
      //     JSON.stringify(apiAllData?.alternate_classifications["3x3"])
      //   );
      // } else if (ruleType === "2 x 2") {
      //   apiAllData.classifications.user_solution = JSON.parse(
      //     JSON.stringify(apiAllData?.alternate_classifications["2x2"])
      //   );
      // }
      updatepanelsDetails = null;

      updatepanels = apiAllData?.classifications?.user_solution;
      if (clearStateUpdate === false) {
          apiAllData.classifications.user_solution = JSON.parse(
          JSON.stringify(apiAllData?.classifications?.algorithm_solution)
      );

      }
      if (clearStateUpdate === false) {
         for (let i = 0; i < apiAllData?.panels?.length; i++) {
          apiAllData.panels[i].user_mask = JSON.parse(
          JSON.stringify(apiAllData?.panels[i]?.mask)
        );
      }
    }
    }

    if (rmToggle === true) {
      if (clearPanels) {
        apiAllData.classifications.user_solution = {ruled_in:[],ruled_out:[],unknown:[]};
        for (let i = 0; i < apiAllData?.panels?.length; i++) {
          apiAllData.panels[i].user_mask = null;
        }
      }
      setContext({ ...apiAllData });
      setLocalApiAllData({ ...apiAllData });
      handleRuleUpdates(apiAllData?.classifications?.user_solution);
    } else if (rmToggle === false) {
      setMode(false);
      setContext({ ...apiAllData });
      setLocalApiAllData({ ...apiAllData });

      // Keeping rules commented code for future requirements
      // if (ruleType === "3 x 3") {
      //   apiAllData?.alternate_classifications &&
      //     handleRuleUpdates(apiAllData?.alternate_classifications["3x3"]);
      // } else if (ruleType === "2 x 2") {
      //   apiAllData?.alternate_classifications &&
      //     handleRuleUpdates(apiAllData?.alternate_classifications["2x2"]);
      // }

      apiAllData?.classifications?.algorithm_solution &&
        handleRuleUpdates(apiAllData?.classifications?.algorithm_solution);
    }
  };

  let [rldClassificObj, setRldCLassificationObj] = useState();
  const allPanelsData = localApiAllData?.panels && localApiAllData?.panels;
  let rldClassification = () => {
    // Keeping rules commented code for future requirements
    // apiAllData?.alternate_classifications &&
    //   setRldCLassificationObj(
    //     rmToggle === false
    //       ? ruleType === "3 x 3"
    //         ? apiAllData?.alternate_classifications["3x3"]
    //         : apiAllData?.alternate_classifications["2x2"]
    //       : localApiAllData?.classifications?.user_solution
    //   );
    setRldCLassificationObj(
      rmToggle === false
        ? apiAllData?.classifications?.algorithm_solution
        : clearPanels ? {ruled_in:[],ruled_out:[],unknown:[]} : apiAllData?.classifications?.user_solution
    );
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeAnchor, setActiveAnchor] = React.useState(null);
  const [headerActiveAnchor, setHeaderActiveAnchor] = React.useState(null);

  const handleClearState = (val) => {
    setActiveAnchor(val);
  };
  const handleHdDropdnClearState = (val) => {
    setHeaderActiveAnchor(val);
  };
  const [selectedCol, setSelectedCol] = useState({});
  const [selectedRow, setSelectedRow] = useState({});

  const colOrder = antigramTableConst.apiColOrder;
  const fiveColumnLabel =
    Constants.antigramTable.workupHeader.autocontrol.summary;

  /* Display table header with classifications and binding cell with other functionality events */
  const headerRowLabels = () => {
    const colOrderCst = antigramTableConst.cstColOrder;
    const headerCells = [];

    const ruleOuts = rldClassificObj?.ruled_out;
    // const ruleOutsSlash = rldClassificObj?.unknown;
    const ruleins = rldClassificObj?.ruled_in;

    for (let j = 0; j < colOrder?.length; j++) {
      if (colOrder[j] === "cell_number" || colOrder[j] === "Spc") {
        headerCells.push(
          <td key={j} className={`hd-cell cl-${j + 1} ${colOrder[j]}`}>
            {colOrderCst[j]}
          </td>
        );
      } else if (colOrder[j] === "is") {
        headerCells.push(
          <td
            key={j}
            colSpan={5}
            className={`hd-cell cl-${j + 1} ${colOrder[j]}`}
          >
            {antigramTableConst.tableHeaderGroup.TestsResults}
          </td>
        );
      } else if (
        colOrder[j] === "37c" ||
        colOrder[j] === "iat" ||
        colOrder[j] === "fic" ||
        colOrder[j] === "fifth_column"
      ) {
        //
      } else {
        let ruledClasses;
        if (ruleOuts && ruleOuts?.indexOf(colOrder[j]) > -1) {
          ruledClasses = "ruled-out-bg";
          // } else if (ruleOutsSlash && ruleOutsSlash?.indexOf(colOrder[j]) > -1) {
          //   ruledClasses = "ruled-out-bg-slash";
        } else if (ruleins && ruleins?.indexOf(colOrder[j]) > -1) {
          ruledClasses = "ruled-in-bg";
        } else {
          ruledClasses = "";
        }

        let selectedColClass =
          colOrder[j] in selectedCol ? selectedCol[colOrder[j]] : "";
        if (
          colOrder[j] === "Rh-hr" ||
          colOrder[j] === "donor_number" ||
          colOrder[j] === "tst-rslt"
        ) {
          headerCells.push(
            <HeaderMenuDropDown
              key={j}
              className={`hd-cell cl-${j + 1} ${
                colOrder[j]
              } ${ruledClasses} ${selectedColClass}`}
              colHighlight={colHighlight.bind(this, colOrder[j])}
              updateHeaderRules={updateHeaderRules}
              statusKey={apiAllData?.workup?.status}
              keyfwd={j}
              anchorEl={anchorEl}
              open={open}
              colKey={colOrder[j]}
              headerActiveAnchor={headerActiveAnchor}
              handleHdDropdnClearState={handleHdDropdnClearState}
              handleHdDropdnClickState={handleHdDropdnClickState}
            >
              {colOrderCst[j]}
            </HeaderMenuDropDown>
          );
        } else {
          headerCells.push(
            <HeaderMenuDropDown
              key={j}
              className={`hd-cell cl-${j + 1} ${
                colOrder[j]
              } ${ruledClasses} ${selectedColClass}`}
              colHighlight={colHighlight.bind(this, colOrder[j])}
              updateHeaderRules={updateHeaderRules}
              statusKey={apiAllData?.workup?.status}
              keyfwd={j}
              anchorEl={anchorEl}
              open={open}
              colKey={colOrder[j]}
              headerActiveAnchor={headerActiveAnchor}
              handleHdDropdnClearState={handleHdDropdnClearState}
              handleHdDropdnClickState={handleHdDropdnClickState}
            >
              <FormattedLabel j={colOrder[j]}></FormattedLabel>
            </HeaderMenuDropDown>
          );
        }
      }
    }
    return headerCells;
  };

  /* Classifications rules change handle */
  const updateHeaderRules = (id, colType, undoRedo = false) => {
    let oldValue;
    let getCurrentValue = localApiAllData?.classifications.user_solution;
      if (getCurrentValue.ruled_in?.length && getCurrentValue.ruled_in.includes(colType)) {
        oldValue = 1;
      }
      else if (getCurrentValue.ruled_out?.length && getCurrentValue.ruled_out.includes(colType)) {
        oldValue = 0;
      } else {
        oldValue = "";
      }

    // let panelSta = getCurrentValue[panelNo].user_mask !== null ? getCurrentValue[panelNo].user_mask : {};
    // const statusPanel = Object.prototype.hasOwnProperty.call(panelSta, colType);

    if (rmToggle === false && manualchange === true) {
      setMode(true);
    } else {
      setMode(false);
      setManualChange(true);
      disableSave();
      // Commenting ruledout slah code for fututre use
      //const maskType = id === 0 ? "X" : id === 1 ? "/" : id === 2 ? "O" : "";
      const maskType = id === 0 ? "X" : id === 1 ? "O" : "";

      rldClassificObj = localApiAllData?.classifications?.user_solution;
      if (rldClassificObj && rldClassificObj?.rule_outs?.length === 0) {
        rldClassificObj.ruled_out = [];
      }
      if (rldClassificObj && rldClassificObj?.unknown?.length === 0) {
        rldClassificObj.unknown = [];
      }
      if (rldClassificObj && rldClassificObj?.ruled_in?.length === 0) {
        rldClassificObj.ruled_in = [];
      }

      let rule_outs = rldClassificObj.ruled_out;
      let rule_outs_slash = rldClassificObj.unknown;
      let rule_ins = rldClassificObj.ruled_in;

      let index;
      if (maskType === "X") {
        index = rule_outs && rule_outs.indexOf(colType);
        if (rule_outs && rule_outs.indexOf(colType) > -1) {
          rule_outs?.splice(index, 1);
          setHeaderActiveAnchor(null);
        } else {
          if (
            rule_outs_slash.indexOf(colType) !== -1 &&
            rule_outs_slash.indexOf(colType) !== undefined
          ) {
            index = rule_outs_slash && rule_outs_slash.indexOf(colType);
            rule_outs_slash?.splice(index, 1);
          }
          if (
            rule_ins.indexOf(colType) !== -1 &&
            rule_ins.indexOf(colType) !== undefined
          ) {
            index = rule_ins && rule_ins.indexOf(colType);
            rule_ins?.splice(index, 1);
          }
          rule_outs.push(colType);

          setHeaderActiveAnchor(id);
        }
        // Commenting ruledout slah code for fututre use
        // } else if (maskType === "/") {
        //   index = rule_outs_slash && rule_outs_slash.indexOf(colType);
        //   if (rule_outs_slash && rule_outs_slash.indexOf(colType) > -1) {
        //     rule_outs_slash?.splice(index, 1);
        //     setHeaderActiveAnchor(null);
        //   } else {
        //     if (
        //       rule_outs.indexOf(colType) !== -1 &&
        //       rule_outs.indexOf(colType) !== undefined
        //     ) {
        //       index = rule_outs && rule_outs.indexOf(colType);
        //       rule_outs?.splice(index, 1);
        //     }
        //     if (
        //       rule_ins.indexOf(colType) !== -1 &&
        //       rule_ins.indexOf(colType) !== undefined
        //     ) {
        //       index = rule_ins && rule_ins.indexOf(colType);
        //       rule_ins?.splice(index, 1);
        //     }
        //     rule_outs_slash.push(colType);
        //     setHeaderActiveAnchor(id);
        //   }
      } else if (maskType === "O") {
        index = rule_ins && rule_ins.indexOf(colType);
        if (rule_ins && rule_ins.indexOf(colType) > -1) {
          rule_ins?.splice(index, 1);
          setHeaderActiveAnchor(null);
        } else {
          if (
            rule_outs.indexOf(colType) !== -1 &&
            rule_outs.indexOf(colType) !== undefined
          ) {
            index = rule_outs && rule_outs.indexOf(colType);
            rule_outs?.splice(index, 1);
          }
          if (
            rule_outs_slash.indexOf(colType) != -1 &&
            rule_outs_slash.indexOf(colType) != undefined
          ) {
            index = rule_outs_slash && rule_outs_slash.indexOf(colType);
            rule_outs_slash?.splice(index, 1);
          }
          rule_ins.push(colType);
          setHeaderActiveAnchor(id);
        }
      } else {
           index = rule_outs_slash && rule_outs_slash.indexOf(colType);
          if (rule_outs_slash && rule_outs_slash.indexOf(colType) > -1) {
            rule_outs_slash?.splice(index, 1);
            setHeaderActiveAnchor(null);
          } else {
            if (
              rule_outs.indexOf(colType) !== -1 &&
              rule_outs.indexOf(colType) !== undefined
            ) {
              index = rule_outs && rule_outs.indexOf(colType);
              rule_outs?.splice(index, 1);
            }
            if (
              rule_ins.indexOf(colType) !== -1 &&
              rule_ins.indexOf(colType) !== undefined
            ) {
              index = rule_ins && rule_ins.indexOf(colType);
              rule_ins?.splice(index, 1);
            }
            rule_outs_slash.push(colType);
            setHeaderActiveAnchor(id);
      }
    }

      rldClassificObj.ruled_out = rule_outs;
      rldClassificObj.unknown = rule_outs_slash;
      rldClassificObj.ruled_in = rule_ins;
      apiAllData.classifications.user_solution = { ...rldClassificObj };

      headerRowLabels();
      updateData();
      handleRMToggle(true);
      let headerPanel = {
        newId: id,
        curpanelHed: oldValue,
        colType,
        type: "panleHeader",
      };
      if (!undoRedo) {
        _maintainStack(headerPanel);
      }
    }
  };

  /* Update test results/Autocontrol datas */
  const openAlertMsg = sessionStorage.getItem(
    apiAllData?.workup?.id + "-showAutoCtrlModifidAlert"
  );
  const [autoAlert, setAutoAlert] = useState(false);
  const updateAutoControllData = (panelNo, colName, val) => {
    let autoConrtolId = null
    for(let j = 0; j < apiAllData?.panels[panelNo]?.results?.length; j++){
      if(apiAllData.panels[panelNo]?.results[j].cell_number === "AC"){
        autoConrtolId = j;
        break;
      }
    }
    openAlertMsg === null ? setAutoAlert(true) : "";
    sessionStorage.setItem(
      apiAllData?.workup?.id + "-showAutoCtrlModifidAlert",
      false
    );
    if (apiAllData?.panels[panelNo]?.panel?.panel_type !== "Screen") {
      apiAllData.panels[panelNo].results[autoConrtolId].columns[colName]["grade"] === val ?
      apiAllData.panels[panelNo].results[autoConrtolId].columns[colName]["grade"] = null :
       apiAllData.panels[panelNo].results[autoConrtolId].columns[colName]["grade"] = val;
    } else {
      apiAllData.panels[panelNo].results[autoConrtolId].columns[colName]["grade"] === val ?
      apiAllData.panels[panelNo].results[autoConrtolId].columns[colName]["grade"] = null :
       apiAllData.panels[panelNo].results[autoConrtolId].columns[colName]["grade"] = val;
      }
    updateData();
    disableSave();
    handleAutoControlIndicator();
  };
  const completedAutoControl = () => {
    completeAlert();
  };
  const [cellDel, setCellDel] = useState(false)
  
  const removeData = (val) =>{  
  updateAutoControllData(val,"fifth_column","NT")
}

const delFifthCol = (val) =>{
  setCellDel(true)
  removeData(val)
}

  let cellDisabled = false
  const analyserAutoControl = Constants?.antigramTable?.workupHeader?.antigenTyping?.disabledTooltip
  let popperCount = 0;
  
  /* Display patient cell row at table bottom */
  const patientcellsrow = (panelNo) => {
    popperCount = popperCount + 1
    const eachCol = [];
    const patientcells = [];
    const fifthColumn = fifthColumnData[panelNo];
    let autoControlIndex = null
    for(let j = 0; j < apiAllData?.panels[panelNo]?.results?.length; j++){
      if(apiAllData.panels[panelNo]?.results[j].cell_number === "AC"){
        autoControlIndex = j;
        break;
      }
    }
    if(popperCount === 1){
    patientcells.push(
      <ClickAwayListener key={Math.random()} onClickAway={handleClickAway}>
        <Popper
          className="autoControlPoppper"
          open={popupState}
          anchorEl={anchorEl2}
          placement={"top-start"}
        >
          <p>{analyserAutoControl}</p>
        </Popper>
      </ClickAwayListener>
    );
    patientcells.push(
      <ClickAwayListener key={Math.random()} onClickAway={handleClickAway2}>
        <Popper
          className="autoControlPoppper"
          open={popupState2}
          anchorEl={anchorEl22}
          placement={"top-start"}
        >
          <p>{fifthColContent}</p>
        </Popper>
      </ClickAwayListener>
    );
    }

    let rowLabel;
    for (let j = 0; j < colOrder?.length; j++) {
      let selectedColClass =
        colOrder[j] in selectedCol ? selectedCol[colOrder[j]] : "";
      if (colOrder[j] === "Rh-hr") {
        rowLabel = antigramTableConst.tableFooterDetails.PatientCells;
        patientcells.push(
          <td
            key={j}
            className={`pt-cell cl-${j + 1} ${colOrder[j]} ${selectedColClass}`}
          >
            {rowLabel}
          </td>
        );
      } else if (
        colOrder[j] === "is" ||
        colOrder[j] === "37c" ||
        colOrder[j] === "iat" ||
        colOrder[j] === "fic" ||
        colOrder[j] === "fifth_column"
      ) {
        rowLabel = apiAllData?.panels[panelNo]?.results[autoControlIndex]?.columns[colOrder[j]]?.grade
        
        rowLabel?.user_entered === false && rowLabel?.grade !== null?
          cellDisabled = true : cellDisabled = false
          
        
        if(colOrder[j] !== "fifth_column"){ 
          statusCompleted !== true && cellDisabled === false
          ? patientcells.push(
              <AutoControlDropDown
                key={j}
                className={`pt-cell cl-${j + 1} ${colOrder[j]} ${
                  fifthColumn.enzyme
                } ${selectedColClass}`}
                panelNo={panelNo}
                colName={colOrder[j]}
                rowLabel={rowLabel}
                updateAutoControllData={updateAutoControllData}
              >
                {rowLabel === "NT" ? null : rowLabel}
              </AutoControlDropDown>
            )
          : 
          statusCompleted === true ?
          patientcells.push(
            <td
              key={j}
              className={`pt-cell cl-${j + 1} ${colOrder[j]
                } ${selectedColClass}`}
              onClick={completedAutoControl}
            >
                {rowLabel === "NT" ? null : rowLabel}
            </td>
          ) :  patientcells.push(
                      <td
                      key={j}
                      className={`pt-cell cl-${j + 1} ${colOrder[j]
                        } analyser ${selectedColClass}`}
                      onClick={handleClickPopper}
                    >
                    {rowLabel === "NT" ? null : rowLabel}
                    </td>
          )
              }
              else{
                if(fifthColumn?.test_type && fifthColumn?.test_type !== ""){
                  statusCompleted !== true && cellDisabled === false
                  ? patientcells.push(
                      <AutoControlDropDown
                        key={j}
                        className={`pt-cell cl-${j + 1} ${colOrder[j]} ${
                          fifthColumn.enzyme
                        } ${selectedColClass}`}
                        panelNo={panelNo}
                        colName={colOrder[j]}
                        rowLabel={rowLabel}
                        updateAutoControllData={updateAutoControllData}
                      >
                    {rowLabel === "NT" ? null : rowLabel}
                      </AutoControlDropDown>
                    )
                  : statusCompleted === true ?
                  patientcells.push(
                    <td
                      key={j}
                      className={`pt-cell cl-${j + 1} ${colOrder[j]
                        } ${selectedColClass}`}
                      onClick={completedAutoControl}
                    >
                    {rowLabel === "NT" ? null : rowLabel}
                    </td>
                  ):  patientcells.push(
                              <td
                              key={j}
                              className={`pt-cell cl-${j + 1} ${colOrder[j]
                                } analyser ${selectedColClass}`}
                                onClick={handleClickPopper}
                            >
                      {rowLabel === "NT" ? null : rowLabel}
                            </td>
                  )
                }
                else{
                  statusCompleted !== true && cellDisabled === false ?
                  patientcells.push(
                      <td
                      key={j}
                      className={`pt-cell cl-${j + 1} ${colOrder[j]
                        } ${selectedColClass}`}
                      onClick={handleClickPopper2}
                    >
                      {rowLabel === "NT" ? null : rowLabel}
                    </td>
                    
                  ) :
                  statusCompleted === true ?
                  patientcells.push(
                    <td
                      key={j}
                      className={`pt-cell cl-${j + 1} ${colOrder[j]
                        } ${selectedColClass}`}
                      onClick={completedAutoControl}
                    >
                      {rowLabel === "NT" ? null : rowLabel}
                    </td>
                  ) :
                  patientcells.push(
                              <td
                              key={j}
                              className={`pt-cell cl-${j + 1} ${colOrder[j]
                                } analyser ${selectedColClass}`}
                                onClick={handleClickPopper}
                            >
                              {rowLabel === "NT" ? null : rowLabel}
                            </td>
                    
                  )
                }
              }
          
      } else {
        rowLabel = "";
        patientcells.push(
          <td
            key={j}
            className={`pt-cell cl-${j + 1} ${colOrder[j]} ${selectedColClass}`}
          >
            {rowLabel}
          </td>
        );
      }
    }
    return patientcells;
  };

  /* Table data masking dropdown handle */
  const handleClickState = (className) => {
    if (className.indexOf("ovl-x") != -1) {
      setActiveAnchor(0);
    } else if (className.indexOf("ovl-slash") != -1) {
      setActiveAnchor(1);
    } else if (className.indexOf("ovl-ellipse") != -1) {
      setActiveAnchor(2);
    } else {
      setActiveAnchor(null);
    }
  };

  /* Table header dropdown handle */
  const handleHdDropdnClickState = (className) => {
    // Commenting ruledout slah code for fututre use
    // if (className.indexOf("ruled-out-bg-slash") != -1) {
    //   setHeaderActiveAnchor(1);
    // }
    if (className.indexOf("ruled-out-bg") != -1) {
      setHeaderActiveAnchor(0);
    } else if (className.indexOf("ruled-in-bg") != -1) {
      setHeaderActiveAnchor(1);
    } else {
      setHeaderActiveAnchor(null);
    }
  };

  let initFlag;
  apiAllData?.workup?.user_analyzed === true
    ? (initFlag = true)
    : (initFlag = false);

  const [mode, setMode] = useState(false);
  const [manualchange, setManualChange] = useState(initFlag);
  const [stackPointer, setStackPointer] = useState(0);
  const [undoStack, setStack] = useState([]);
  const [undoDisabled, setUndoDisabled] = useState(true);
  const [redoDisabled, setRedoDisabled] = useState(true);
  const [undoFlag, SetundoFlag] = useState(false);
  const [redoFlag, SetredoFlag] = useState(false);
  const [deleteflagStatus, SetDeleteflagStatus] = useState(false);
  const [clearDisabled, SetclearDisabled] = useState(false);

  const _resetStack = () => {
    SetDeleteflagStatus(true);
  };

  function redoHandler() {
    // To move the pointer position
    let currentStackAt = 0;
    SetredoFlag(true);

    if (undoFlag) {
      currentStackAt = stackPointer + 1;
      SetundoFlag(false);
    } else {
     
      currentStackAt = stackPointer + 2;

    }
    if (stackPointer >= undoStack?.length) {
      // Do Nothing
    
      currentStackAt = undoStack?.length - 1;
      setStackPointer(undoStack?.length);
    } else {
      setStackPointer(currentStackAt);
    }

    var data = undoStack[currentStackAt];
    console.log(data)
    let obj = data.val;
    obj["undoRedo"] = true;
    if ("type" in obj) {
      let { newId, colType, undoRedo } = obj;
      updateHeaderRules(newId, colType, undoRedo);
    } else {
      let { newId, colType, rowNumber, panelNo, undoRedo } = obj;
      handleChange(newId, colType, rowNumber, panelNo, undoRedo, true);
    }

    // To disable or enable buttons.
    if (currentStackAt >= undoStack?.length - 1) {
      // Last point
      setRedoDisabled(true);
      setUndoDisabled(false);
    } else {
      // Do Nothing.
      setRedoDisabled(false);
      setUndoDisabled(false);
    }
  }
  function undoHandler() {
    // To move the pointer position
    let currentStackAt =0;
    SetundoFlag(true);
    if (redoFlag) {
   
      currentStackAt = stackPointer - 1;
      SetredoFlag(false);
    } else {
      currentStackAt = stackPointer - 2;
    }
    if (currentStackAt <= 0) {
      currentStackAt = 0;
      setStackPointer(0);
    } else {
      setStackPointer(currentStackAt);
    }
    var data = undoStack[currentStackAt];
    let obj = data.val;
    obj["undoRedo"] = true;
    if ("type" in obj) {
      let { curpanelHed, colType, undoRedo } = obj;
      updateHeaderRules(curpanelHed, colType, undoRedo);
    } else {
      let { oldId, colType, rowNumber, panelNo, undoRedo } = obj;
      handleChange(oldId, colType, rowNumber, panelNo, undoRedo, true);
    }
    // To disable or enable buttons.
    if (currentStackAt == 0) {
      // Last point
      setUndoDisabled(true);
      setRedoDisabled(false);
    } else {
      // Do Nothing.
      setUndoDisabled(false);
      setRedoDisabled(false);
    }
  }
  const  cleanNullValues=(object)=> {
    Object
        .entries(object)
        .forEach(([k, v]) => {
            if (v && typeof v === 'object') {
              cleanNullValues(v);
            }
            if (v && typeof v === 'object' && !Object.keys(v)?.length || v === "" || v === null) {
                if (Array.isArray(object)) {
                    object.splice(k, 1);
                } else {
                    delete object[k];
                }
            }
        });
    return object;
}
  /* Table Masking change function */
  const handleChange = (id, colType, rowNumber, panelNo, undoRedo = false, isRedo) => {
    if (!isRedo)
      rowNumber = localApiAllData?.panels[panelNo]?.panel.rows[rowNumber - 1]?.cell_number; // translated for panel b

    let getCurrentValue = localApiAllData?.panels;
    let panelSta =
      getCurrentValue[panelNo].user_mask !== null
        ? getCurrentValue[panelNo].user_mask
        : {};

    Object.keys(panelSta).forEach((key) => {
      if (panelSta[key] === null) {
        delete panelSta[key];
      }
    });
    const statusPanel = Object.prototype.hasOwnProperty.call(panelSta, colType);
    const getCurrentPanelStatus = statusPanel
      ? localApiAllData?.panels[panelNo]?.user_mask[colType][rowNumber]
      : "";
    let getCurrentId =
      getCurrentPanelStatus === "X"
        ? 0
        : getCurrentPanelStatus === "/"
        ? 1
        : getCurrentPanelStatus === "O"
        ? 2
        : "";
    if (rmToggle === false && manualchange === true) {
      setMode(true);
    } else {
      setMode(false);
      setManualChange(true);
      disableSave();
      let maskObj =
        localApiAllData?.panels[panelNo]?.user_mask !== null
          ? localApiAllData?.panels[panelNo]?.user_mask
          : {};
      const maskType = id === 0 ? "X" : id === 1 ? "/" : id === 2 ? "O" : "";
      let changeType = "add";
      if (maskObj !== null && colType in maskObj) {
        if (maskObj[colType] && maskObj[colType][rowNumber] === maskType) {
          // maskObj[colType][rowNumber] = "";
          delete maskObj[colType][rowNumber];
          setActiveAnchor(null);
        } else {
          maskObj[colType] = { ...maskObj[colType], [rowNumber]: maskType };
          setActiveAnchor(id);
        }
      } else {
        maskObj[colType] = { [rowNumber]: maskType };
      }
      let maskobjects=cleanNullValues({ ...maskObj });
      localApiAllData.panels[panelNo].user_mask = { ...maskobjects };
      updateData();
      handleRMToggle(true);
      if (getCurrentId === id) {
        id = "";
      }
      let currentChange = {
        oldId: getCurrentId,
        newId: id,
        colType,
        rowNumber,
        panelNo,
      };
      if (!undoRedo) {
        _maintainStack(currentChange);
      }
    }
  };
  const _maintainStack = (value) => {
    console.log(value)
    SetclearDisabled(false);
    let newValue;
    let oldValue;
    if ("type" in value) {
      let { newId, curpanelHed, colType, type } = value;
      newValue = { newId, colType, type };
      oldValue = { curpanelHed, colType, type };
    } else {
      let { oldId, newId, colType, rowNumber, panelNo } = value;
      newValue = { newId, colType, rowNumber, panelNo };
      oldValue = { oldId, colType, rowNumber, panelNo };
    }
    const id = Math.random() * 1000;
    var undoval = { id: id, val: oldValue, isUndo: true };
    var redoval = { id: id, val: newValue, isUndo: false };
    if (stackPointer < undoStack?.length - 1) {
      // TODO
      if (stackPointer === 0) {
        // undoStack = [];
        let newStack2 = [];
        newStack2.push(undoval, redoval);
        setStack(newStack2);
        setStackPointer(newStack2?.length);
      } else {
        var currentVal = undoStack[stackPointer - 1];
        var cutoffPointer = stackPointer;
        if (currentVal.isUndo) {
          cutoffPointer = stackPointer;
        } else {
          cutoffPointer = stackPointer + 1;
        }
        var newStack = undoStack.slice(0, cutoffPointer - 1);
        // undoStack = [];
        newStack.push(undoval, redoval);
        setStack(newStack);
        setStackPointer(newStack?.length);
      }

      // stackPointer = undoStack.length;
      // setStackPointer(undoStack.length)
    } else {
      undoStack.push(undoval, redoval);
      //stackPointer = undoStack.length;
      setStackPointer(undoStack?.length);
    }
    SetredoFlag(false);
    // isRedo = false;
    setRedoDisabled(true);
    if (undoDisabled) {
      setUndoDisabled(false);
    }
  };

  /* Column Heighlight function */
  const colHighlight = (param, event) => {
    if (event.target === event.currentTarget) {
      if (event.ctrlKey === true) {
        if (
          param in selectedCol &&
          selectedCol[param] != undefined &&
          selectedCol[param] != ""
        ) {
          setSelectedCol({ ...selectedCol, [param]: "" });
        } else {
          setSelectedCol({ ...selectedCol, [param]: "highlight-col" });
        }
      } else {
        if (
          param in selectedCol &&
          selectedCol[param] != undefined &&
          selectedCol[param] != ""
        ) {
          setSelectedCol({ [param]: "" });
        } else {
          setSelectedCol({ [param]: "highlight-col" });
        }
      }
    }
    if (statusCompleted) {
      completeAlert();
    }
  };

  /* Row Heighlight function */
  const rowHighlight = (param, event) => {
    if (event.ctrlKey === true) {
      if (
        param in selectedRow &&
        selectedRow[param] != undefined &&
        selectedRow[param] != ""
      ) {
        setSelectedRow({ ...selectedRow, [param]: "" });
      } else {
        setSelectedRow({ ...selectedRow, [param]: "highlight-row" });
      }
    } else {
      if (
        param in selectedRow &&
        selectedRow[param] != undefined &&
        selectedRow[param] != ""
      ) {
        setSelectedRow({ [param]: "" });
      } else {
        setSelectedRow({ [param]: "highlight-row" });
      }
    }
  };

  /* Loop table columns */
  const anitigenColsPanel = (i, rows, panelNo) => {
    const eachCol = [];
    const { test_type: fifthColumnName, enzyme: fifthColumnEnzyme } =
      fifthColumnData[panelNo];
    const getPanelData = allPanelsData && allPanelsData[panelNo];

 /* checking for index of cell_number type "AC" */
    let patientCellCol = null
    for(let j = 0; j < apiAllData?.panels[panelNo]?.results?.length; j++){
      if(getPanelData?.results[j].cell_number === "AC"){
        patientCellCol = j
      }
    }

    const maskingfun = (colType) => {
      let maskType;
      if (rmToggle === false) {
        maskType =
          getPanelData?.mask?.[colType] && getPanelData?.mask?.[colType][rows[i]?.cell_number];
      } else {
        maskType =
          getPanelData?.user_mask?.[colType] &&
          getPanelData?.user_mask?.[colType][rows[i]?.cell_number];
      }
      let mskclass;

      if (maskType === "X") {
        mskclass = "ovl-x";
      } else if (maskType === "/") {
        mskclass = "ovl-slash";
      } else if (maskType === "O") {
        mskclass = "ovl-ellipse";
      } else {
        mskclass = "";
      }
      return mskclass;
    };
    for (let j = 0; j < colOrder.length; j++) {
      
      let resVal = null;
      /*ordering the result data from api in ascending order*/
      let results = getPanelData?.results;
      for (let k = 0; k <= 11; k++) {
        if (results[k] !== undefined){
          if (+results[k]?.cell_number == rows[i]?.cell_number) {
            resVal = results[k]?.columns[colOrder[j]]
            break
          }
        }
      }
      let getRowClass = "pnl-" + (panelNo + 1) + "rw-" + (i + 1);
      let selectedColClass =
        colOrder[j] in selectedCol ? selectedCol[colOrder[j]] : "";
      let selectedRowClass =
        getRowClass in selectedRow ? selectedRow[getRowClass] : "";

      if (
        colOrder[j] === "is" ||
        colOrder[j] === "37c" ||
        colOrder[j] === "iat" ||
        colOrder[j] === "fic"
      ) {
        eachCol.push(
          <td
            key={j}
            className={`rw-${i + 1} cl-${j + 1} ${
              colOrder[j]
            } tst-columns ${selectedColClass} ${selectedRowClass}`}
          >
            {resVal !== null ? resVal?.grade : null}
          </td>
        );
      } else if (colOrder[j] === "fifth_column") {
        eachCol.push(
          <td
            key={j}
            
            className={`rw-${i + 1} cl-${j + 1} ${colOrder[j]
              } tst-columns ${fifthColumnEnzyme} ${selectedColClass} ${selectedRowClass}`}
          >
            {
            patientCellCol !== null ? resVal !== null ?
             resVal?.grade : null : getStatus(undefinedErr, navigate)
            }
          </td>
        );
      } else if (colOrder[j] === "cell_number") {
        eachCol.push(
          <td
            key={j}
            className={`rw-${i + 1} cl-${j + 1} ${
              colOrder[j]
            }  ${selectedRowClass}`}
            onClick={rowHighlight.bind(this, `pnl-${panelNo + 1}rw-${i + 1}`)}
          >
            {rows[i]?.cell_number}
          </td>
        );
      } else if (colOrder[j] === "Spc") {
        eachCol.push(
          <td
            key={j}
            className={`rw-${i + 1} cl-${j + 1} ${
              colOrder[j]
            } ${selectedRowClass}`}
          >
            {getPanelData?.panel?.rows[i][colOrder[j]]}
          </td>
        );
      } else if (colOrder[j] === "Rh-hr" || colOrder[j] === "donor_number") {
        eachCol.push(
          <td
            key={j}
            className={`rw-${i + 1} cl-${j + 1} ${colOrder[j]} panel-${
              panelNo + 1
            } pnl-${panelNo + 1}rw-${
              i + 1
            } ${selectedColClass} ${selectedRowClass}`}
          >
            {getPanelData?.panel?.rows[i][colOrder[j]]}
          </td>
        );
      } else {
        eachCol.push(
          <MenuDropdown
            statusKey={apiAllData?.workup?.status}
            key={j}
            keyFwd={j}
            panelNo={panelNo}
            className={`rw-${i + 1} cl-${j + 1} ${colOrder[j]} panel-${
              panelNo + 1
            } pnl-${panelNo + 1}rw-${
              i + 1
            } ${selectedColClass} ${selectedRowClass} ${maskingfun(
              colOrder[j]
            )}`}
            anchorEl={anchorEl}
            open={open}
            colKey={colOrder[j]}
            rowNumber={i + 1}
            // handleClick={handleClick}
            handleChange={handleChange}
            activeAnchor={activeAnchor}
            handleClearState={handleClearState}
            handleClickState={handleClickState}
            completeAlert={completeAlert}
            // handleClose={handleClose}
          >
            {getPanelData?.panel?.rows[i][colOrder[j]]}
          </MenuDropdown>
        );
      }
    }

    return eachCol;
  };


  /* Loop table rows */
  const anitigenRowsPanel = (panelNo, panel) => {
    const getPanelData = allPanelsData && allPanelsData[panelNo];
    const eachRow = [];

    let rows = getPanelData?.panel?.rows;

    for (let i = 0; i < rows?.length; i++) {
      if (rows[i]?.cell_number === "AC") {
        eachRow.push();
      } else {
        eachRow.push(
          <tr className={`row-${i + 1}`} key={`row-${i + 1}`}>
            {anitigenColsPanel(i, rows, panelNo)}
          </tr>
        );
      }
    }

    eachRow.push(<tr key={panelNo}>{patientcellsrow(panelNo)}</tr>);
    return eachRow;
  };

  /* Loop table panels */
  const LoopPanels = (panelKey, key) => {
    const { test_type: fifthColumnName, enzyme: fifthColumnEnzyme } =
      fifthColumnData[key] || {};
    return (
      <React.Fragment>
        <tbody
          id={key + "panel"}
          className="antigram-header-data panel-a panel-a-header"
        >
          <tr>
            <td className="panel-a-detail-row" colSpan={32}>
              <div className="panel-a-row">
                <span
                  className="expand-icon"
                  onClick={() => updateAccState(key)}
                >
                  {accExpand[key] ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </span>
                <span className="panel-name">
                  {allPanelsData[key]?.panel?.panel_type !== "Screen" && antigramTableConst.panelDetails.panelName}{" "}
                  {allPanelsData[key]?.panel?.panel_type}
                </span>
                <span className="lot-no">
                  <span>{antigramTableConst.panelDetails.lotNo}</span>{" "}
                  {allPanelsData && allPanelsData[key]?.panel?.lot_number}
                  {allPanelsData[key]?.panel?.expiration_date &&
                    " / " +
                      antigramTableConst.panelDetails.exp +
                      " " +
                      dayjs(allPanelsData[key]?.panel?.expiration_date).format(
                        DateFormat()
                      )}
                </span>
                <span className="sample-id">
                  <b> {antigramTableConst.panelDetails.sampleId} </b>
                  {allPanelsData[key]?.panel?.sample?.unhashed.length > 20 ? (
                    <LightTooltip
                      className="view-only-hover-popUp"
                      title={allPanelsData[key]?.panel?.sample?.unhashed}
                      arrow
                      placement="top-start"
                    >
                      <span>
                        {allPanelsData[key]?.panel?.sample?.unhashed &&
                          allPanelsData[key]?.panel?.sample?.unhashed.substring(
                            0,
                            15
                          ) + "..."}
                      </span>
                    </LightTooltip>
                  ) : (
                    <span>
                      {allPanelsData[key]?.panel?.sample?.unhashed &&
                        allPanelsData[key]?.panel?.sample?.unhashed}
                    </span>
                  )}
                </span>
                &nbsp;{"/"}&nbsp;
                <span className="sample-date">
                  <b> {antigramTableConst.panelDetails.sampleDate} </b>
                  {allPanelsData[key]?.panel?.sample?.date_time &&
                    dayjs(allPanelsData[key]?.panel?.sample?.date_time).format(
                      DateFormat()
                    )}
                </span>
                <span id="download-remove" className="panel-note-label">
                  <FlyoutBox
                    workup = {apiAllData.workup.id}
                    panelId={panelKey?.panel?.id}
                    label={antigramTableConst?.panelDetails?.panelNotes}
                    statusFilter={apiAllData?.workup?.status}
                  />
                </span>
                <span
                  id="download-remove"
                  onClick={() => handleOpen(key)}
                  className="see-details-label"
                >
                  {antigramTableConst.panelDetails.SeeDetails}
                </span>
              </div>
            </td>
            <td className="tr-col is cl-title">{fiveColumnLabel.is}</td>
            <td className="tr-col 37c cl-title">{fiveColumnLabel.c}</td>
            <td className="tr-col iat cl-title">{fiveColumnLabel.iat}</td>
            <td className="tr-col fic cl-title">{fiveColumnLabel.ficin}</td>
            <td
              className={`tr-col fifth_column cl-title ${fifthColumnEnzyme}`}
              onClick={() => handlePanelEditName(key)}
            >
              {(fifthColumnName && fifthColumnName !== "") ? (
                <LightTooltip
                className="view-only-hover-popUp"
                title={fifthColumnName?.length > 3 ? fifthColumnName : ""}
                arrow
                placement="top-start"
              >
                <span className="fifth-col-head">{fifthColumnName?.substring(0, 3)}</span>
              </LightTooltip>
              ) : (
                <EditOutlinedIcon id="download-remove" />
              )}
            </td>
          </tr>
        </tbody>
        {accExpand[key] && (
          <tbody
            id={key + "download"}
            className="antigram-main-data panel-a panel-a-table"
          >
            {anitigenRowsPanel(key, panelKey)}
          </tbody>
        )}
      </React.Fragment>
    );
  };

  let panelsLength = {};
  for (var key in apiAllData?.panels) {
    panelsLength[key] = true;
  }
  const [accExpand, setAccExpand] = React.useState(panelsLength);
  const [clearpanelMsg,setClearpanelMsg] = useState(false);
  const updateAccState = (itm) => {
    setAccExpand({
      ...accExpand,
      [itm]: !accExpand[itm],
    });
  };

  const [findHeaderHeight, setFindHeaderHeight] = React.useState(0);
  useEffect(() => {
    window.addEventListener("scroll", function () {
      const headerHeight = document.getElementsByClassName(
        "antigram-panel-header-wrapper"
      )[0]?.clientHeight;
      setFindHeaderHeight(headerHeight);
    });
  });
  
  const saveSuccessfulMeassage = () => {
    return (
      <div>
        <CheckCircleIcon
          className="warning-icon"
          style={{ color: "#789D4A" }}
        />
        <span className="alert-note">
          <strong>{undoredoclearWarMsg.clear}: </strong>{undoredoclearWarMsg.workupclearmsg}
        </span>
      </div>
    );
  };
  const SaveSuccessAlert = () => {
    return (
      <PositionedSnackbar
        fwdStyles={{
          color: "#34383C",
          backgroundColor: "#EEFFF1",
          borderColor: "#789D4A",
          justifyContent: "space-between",
          // onClose={}
        }}
      >
        {saveSuccessfulMeassage()}
      </PositionedSnackbar>
    );
  };
  const handleDeleteWarningClose = () => {
    SetDeleteflagStatus(false);
  };
  const handleDeleteWarningYes = () => {
    SetDeleteflagStatus(false);
    clearPanels = true;
    setClearStateUpdate(true)
    updateData();
    disableSave();
    setStackPointer(0);
    setStack([]);
    setUndoDisabled(true);
    setRedoDisabled(true);
    setClearpanelMsg(true)
    SetclearDisabled(true);
  };
  //on download make all panels expand
  useEffect(() => {
    if (panelControl) {
      setAccExpand(panelExpand);
    }
  }, [panelExpand]);
  
   /*ordering the interpretation panels*/
  const orderArray = Constants?.workupHomePage?.orderedPanelArr
  let valKey = []
  const orderingPanels = () =>{
    for(let a=0; a<orderArray?.length; a++){
      let panelData = null;
      
     for(let b = 0; b < apiAllData?.panels?.length; b++){
      if(!valKey.includes(b)){
      if(orderArray[a] === allPanelsData[b]?.panel?.panel_type){
        panelData = allPanelsData[b]
        valKey.push(b)
        downloadWorkupPanelOrder(b)
        return (
          <React.Fragment key={valKey[valKey.length - 1]}>
            {LoopPanels(panelData, valKey[valKey.length - 1])}
          </React.Fragment>
        );
      }
    }
      
     }
     
    }
    
  }

  return (
    <div className="table-wrapper">
      {clearpanelMsg && SaveSuccessAlert()}
      {deleteflagStatus && (
        <DeleteWarning
          title={undoredoclearWarMsg?.cleartitle}
          bodyTitle={undoredoclearWarMsg?.clearBOdy}
          deleteMsg={undoredoclearWarMsg?.clearsucessmsg}
          handleDeleteWarningClose={() => {
            handleDeleteWarningClose();
          }}
          handleDeleteWarningYes={() => {
            handleDeleteWarningYes();
          }}
        />
      )}
      <ViewPanelDetails
        panelApiResponse={panelApiResponse}
        open={openPanel}
        panelClose={panelClose}
        load={load}
      />
      {/* AutoControl Alert message Code Commented for Future Use */}
      {/* {autoAlert && (
        <PositionedSnackbar
          fwdStyles={{
            color: "#34383C",
            backgroundColor: "#FFF2D0",
            borderColor: "#F2A900",
          }}
        >
          {warningMessage()}
        </PositionedSnackbar>
      )} */}
      {rmToggle === false && mode === true && manualchange === true ? (
        <AlertNote
          updateData={updateData}
          handleRMToggle={handleRMToggle}
          setMode={setMode}
        />
      ) : null}
      {apiAllData?.panels === null ? (
        <div className="antigram-no-test-results">
          {antigramTableConst.noTestResultMsg}
        </div>
      ) : (
        <table>
          <tbody
            id="workup-download"
            className="antigram-header-data antigram-header-sticky"
            style={{ top: findHeaderHeight }}
          >
            <tr className="table-group-heading">
              <td colSpan={3}>
                <div
                  id="clear-button"
                  className={
                    rmToggle
                      ? "undo-redo-container-active"
                      : "undo-redo-container"
                  }
                >
                  <div className="undo-redo-wrapper">
                    <button
                      disabled={undoDisabled || statusCompleted || !rmToggle }
                      onClick={undoHandler}
                      className="undo-button"
                    >
                      <UndoIcon className="undoredo-icon" />
                    </button>
                    <button
                      disabled={redoDisabled || statusCompleted || !rmToggle}
                      onClick={redoHandler}
                      className="redo-button"
                    >
                      <RedoIcon className="undoredo-icon" />
                    </button>
                  </div>
                  <div className="clear-button">
                    <button
                      className="table-clear-button"
                      disabled={
                        !rmToggle ||
                        (rmToggle && clearDisabled) ||
                        statusCompleted
                      }
                      onClick={_resetStack}
                    >
                      {undoredoclearWarMsg.clear}
                    </button>
                  </div>
                </div>
              </td>
              <td colSpan={8}>{antigramTableConst.tableHeaderGroup.RhHr}</td>
              <td colSpan={6}>{antigramTableConst.tableHeaderGroup.Kell}</td>
              <td colSpan={2}>{antigramTableConst.tableHeaderGroup.Duffy}</td>
              <td colSpan={2}>{antigramTableConst.tableHeaderGroup.Kidd}</td>
              <td>{antigramTableConst.tableHeaderGroup.SexLinked}</td>
              <td colSpan={2}>{antigramTableConst.tableHeaderGroup.Lewis}</td>
              <td colSpan={4}>{antigramTableConst.tableHeaderGroup.MNS}</td>
              <td>{antigramTableConst.tableHeaderGroup.P}</td>
              <td colSpan={2}>
                {antigramTableConst.tableHeaderGroup.Lutheran}
              </td>
              <td colSpan={6}></td>
            </tr>

            <tr className="cell-row-header">{headerRowLabels()}</tr>
          </tbody>

          {
            allPanelsData.map((panelKey)=>
            orderingPanels()
            )
            }

          <tbody>
            <tr className="table-group-footer">
              <td colSpan={3}>
                {antigramTableConst.tableFooterDetails.ModeReactivity}
              </td>
              <td colSpan={8}>
                {antigramTableConst.tableFooterDetails.CAntiglobulin}
              </td>
              <td colSpan={11}>
                {antigramTableConst.tableFooterDetails.Antiglobulin}
              </td>
              <td colSpan={4}>
                {antigramTableConst.tableFooterDetails.Variable}
              </td>
              <td colSpan={3}>{antigramTableConst.tableFooterDetails.Cold}</td>
              <td colSpan={2}>{antigramTableConst.tableFooterDetails.Var}</td>
            </tr>
          </tbody>
        </table>
      )}
      <NameChangeDrawer
        handleNameChange={handleNameChangeDrawer}
        onClose={handleNameChangeDrawerClose}
        isOpen={isNameChangeDrawerOpen}
        nameChangeData={fifthColumnData[nameChngPanelIndex]}
        disableSave={disableSave}
        nameChngPanelIndex={nameChngPanelIndex}
        removeData={removeData}
        delFifthCol={delFifthCol}
      />
      
    </div>
  );
};
export default AntigramTable;
